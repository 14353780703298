import styled, { css } from 'styled-components'
import { animated } from 'react-spring'

export const Hamburger = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 3rem;
	height: 100%;
`

const hamburgerLineBaseStyles = css`
	position: relative;
	height: 2px;
	width: 100%;
	background-color: ${({ theme }) => theme.colors.primary};
`

export const HamburgerLine1 = styled(animated.div)`
	${hamburgerLineBaseStyles};
`

export const HamburgerLine2 = styled(animated.div)`
	${hamburgerLineBaseStyles};
`

export const HamburgerLine3 = styled(animated.div)`
	${hamburgerLineBaseStyles};
`
