import styled, { css } from 'styled-components'
import { media } from 'constants/media/media'
import { zIndex } from 'constants/zIndex/zIndex'
import { pulse } from 'constants/keyframes/pulses'

export const BasketIconWrapper = styled.div``

export const BasketTrigger = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	line-height: 100%;
	font-size: 1.8rem;
	font-weight: 600;
	color: ${({ theme }) => theme.colors.primary};
	transition: background-color 200ms;
	margin: 0 2rem 0 2rem;
	-webkit-tap-highlight-color: transparent;
	cursor: pointer;

	svg path {
		transition: fill 200ms ease;
	}

	${media.desktopSmall`
		width: 8rem;
		margin: 0;
	`}
`

export const BasketCount = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	height: 2rem;
	width: 2rem;
	color: ${({ theme }) => theme.colors.white};
	font-size: 1.2rem;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: background-color 200ms;

	&.active {
		animation: ${pulse} 0.5s;
	}
`

interface WrapperProps {
	active: boolean
}

export const Wrapper = styled.div<WrapperProps>`
	position: relative;

	${({ active }) =>
		active &&
		css`
			z-index: ${zIndex.modals};
		`}

	${BasketTrigger} {
		background-color: ${({ active, theme }) => (active ? theme.colors.white : theme.colors.primary)};

		${media.tabletAndMobileOnly`
			background-color: ${({ theme }) => theme.colors.white};
		`}

		${BasketIconWrapper} {
			svg path {
				fill: ${({ active, theme }) => (active ? 'initial' : theme.colors.white)};
			}

			${media.tabletAndMobileOnly`
				svg path {
					fill: ${({ theme }) => theme.colors.gray};
				}
			`}
		}

		${BasketCount} {
			background-color: ${({ active, theme }) => (active ? theme.colors.secondary : theme.colors.textDark)};

			${media.tabletAndMobileOnly`
				background-color: ${({ theme }) => theme.colors.textDark};
			`}
		}
	}

	&.fixed {
		${media.desktopLarge`
			${BasketTrigger} {
				background-color: ${({ theme }) => theme.colors.white};
				cursor: initial;
				pointer-events: none;

				${BasketIconWrapper} {
					svg path {
						fill: initial;
					}
				}

				${BasketCount} {
					background-color: ${({ theme }) => theme.colors.secondary};
				}
			}
		`}
	}
`
