import { useActiveCompany } from 'hooks/useActiveCompany/useActiveCompany'
import { useEffect } from 'react'
import useTypedSelector from 'interfaces/useTypedSelector'
import { useDispatch } from 'react-redux'
import { getStorageBasket } from 'helpers/basketHelpers/basketHelpers'
import { fetchBasket } from 'reducers/basketReducer'

export const useBasketStorage = () => {
	const dispatch = useDispatch()
	const { activeCompanyId, productStatus } = useTypedSelector(({ basket }) => basket)
	const { activeRelation } = useActiveCompany()

	// Listens for a change to active company and updates the cart to fetch the items
	// associated to that ID
	useEffect(() => {
		if (
			activeRelation &&
			activeRelation.company.id !== activeCompanyId &&
			(productStatus === 'passive' || productStatus === 'fulfilled')
		) {
			const storedBasket = getStorageBasket(activeRelation.company.id)
			dispatch(fetchBasket(activeRelation.company.id, storedBasket.products))
		}
	}, [activeRelation, activeCompanyId, productStatus])
}

export default useBasketStorage
