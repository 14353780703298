import products from 'apiService/products'
import StorefrontProduct from '@local-types/products/storefrontProduct'
import StorefrontCategory from '@local-types/categories/storefrontCategory'
import categories from 'apiService/categories'

export interface SearchResults {
	products: StorefrontProduct[] | null
	categories: StorefrontCategory[] | null
}

export const get = async (value: string, companyId?: string): Promise<SearchResults> => {
	const prods = await products.get(
		{
			page: 1,
			search: value,
		},
		companyId
	)
	const cats = await categories.get({
		search: value,
	})

	return {
		products: prods.data.results.length > 0 ? prods.data.results : null,
		categories: cats.data.length > 0 ? cats.data : null,
	}
}
