import React, { FC, useEffect } from 'react'
import * as StyledMenu from './headerSearchMenu.styles'
import { Spinner } from 'components/base/spinner/spinner'
import { useSpring } from 'react-spring'
import useMeasure from 'hooks/useMeasure'
import { useDebounce } from 'use-debounce'
import Link from 'next/link'
import { Strong } from 'components/base/typography/misc'
import { IoIosSearch } from 'react-icons/io'
import { useSearch } from 'hooks/useSearch/useSearch'
import { Flex } from 'rebass'
import { H5 } from 'components/base/typography/headings'
import SimpleListProductBox from 'components/products/productBoxes/simpleListProductBox'
import useFilters from 'hooks/useFilters'
import { useDispatch } from 'react-redux'
import { toggleCatNavMenu, toggleMobileMenuVisibility } from 'reducers/catNavReducer'
import useTypedSelector from 'interfaces/useTypedSelector'

interface Props {
	value: string
}

const HeaderSearchMenu: FC<Props> = ({ value }) => {
	const dispatch = useDispatch()
	const [debouncedValue] = useDebounce(value, 300)
	const { bind, bounds } = useMeasure()
	const { data, loading, error, search } = useSearch()
	const { menuOpen, mobileMenuActive } = useTypedSelector(({ catNav }) => catNav)
	const props = useSpring({ height: menuOpen ? bounds.height : 0 })
	const { clearFilters } = useFilters()

	useEffect(() => {
		dispatch(toggleCatNavMenu(debouncedValue.length > 0))
		if (debouncedValue.length > 2) search(debouncedValue)
	}, [debouncedValue])

	const closeMenu = () => {
		if (mobileMenuActive) dispatch(toggleMobileMenuVisibility(false))
		dispatch(toggleCatNavMenu(false))
	}

	const handleSeeAllResultsClick = () => {
		closeMenu()
		clearFilters(debouncedValue)
	}

	return (
		<StyledMenu.Wrapper style={props}>
			{menuOpen && <StyledMenu.Overlay onClick={closeMenu} />}
			<StyledMenu.Inner {...bind}>
				{loading && (
					<StyledMenu.SpinnerWrapper>
						<Spinner />
					</StyledMenu.SpinnerWrapper>
				)}
				{error && (
					<Flex m="5rem 0" justifyContent="center">
						<H5>Villa kom upp við að sækja leitarniðurstöður</H5>
					</Flex>
				)}
				{data.categories?.slice(0, 3).map(cat => (
					<div key={cat.id} onClick={closeMenu}>
						<Link href="/flokkur/cslug" as={`/flokkur/${cat.slug}`}>
							<StyledMenu.CategoryResultLink href={`/flokkur/${cat.slug}`}>
								<Strong>{cat.name}</Strong>
								<div>Vöruflokkur</div>
							</StyledMenu.CategoryResultLink>
						</Link>
					</div>
				))}
				{data.products?.map(product => (
					<div key={product.id} onClick={closeMenu}>
						<SimpleListProductBox value={value} product={product} />
					</div>
				))}
				<Link href="/vorur">
					<StyledMenu.MenuBottomLink href="/vorur" onClick={handleSeeAllResultsClick}>
						<StyledMenu.MenuBottomIconWrapper>
							<IoIosSearch />
						</StyledMenu.MenuBottomIconWrapper>
						<div>{`Sjá allar leitarniðurstöður fyrir "${value}"`}</div>
					</StyledMenu.MenuBottomLink>
				</Link>
			</StyledMenu.Inner>
		</StyledMenu.Wrapper>
	)
}

export default HeaderSearchMenu
