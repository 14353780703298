import { createGlobalStyle } from 'styled-components'
import { media } from 'constants/media/media'
import { zIndex } from 'constants/zIndex/zIndex'

const GlobalStyle = createGlobalStyle`
	* {
		border: 0;
		box-sizing: inherit;
		-webkit-font-smoothing: antialiased;
		font-weight: inherit;
		margin: 0;
		outline: 0;
		padding: 0;
		text-decoration: none;
		text-rendering: optimizeLegibility;
		-webkit-appearance: none;
		-moz-appearance: none;
	}

	html {
		font-size: 62.5%;
	}

	html, body {
		min-height: 100%;
	}

	img, picture {
		max-width: 100%;
	}

	body {
		position: relative;
		max-width: 100vw;
		margin: 0;
		padding: 0;
		color: ${({ theme }) => theme.colors.textColor};
		font-size: ${({ theme }) => theme.fonts.fontSize};
		font-family: ${({ theme }) => theme.fonts.fontFamily};
		font-weight: 300;
		line-height: 1.75;
		box-sizing: border-box;
		overflow-y: scroll;
		overflow-x: hidden;
		-webkit-overflow-scrolling: touch;

		${media.desktopSmall`
			font-size: ${({ theme }) => theme.fonts.fontSizeLarge};
		`}
	}

	.Toaster__alert_close { display: none; }

	/* Override styles for Cookiehub */
	.ch2-dialog {
		background: ${({ theme }) => theme.colors.primary} !important;

		.ch2-dialog-content p a {
			color: #ffffff !important;
			border-bottom: 1px solid #ffffff !important;
		}

		#ch2-dialog-title {
			strong {
				color: #ffffff !important;
			}
		}

		#ch2-dialog-description {
			color: #ffffff !important;
		}

		.ch2-btn {
			border-radius: 3rem !important;
		}

		.ch2-allow-all-btn {
			background: #ffffff !important;
			color: #313131 !important;
		}

		.ch2-open-settings-btn {
			font-size: 12px !important;
		}
	}
`

export default GlobalStyle
