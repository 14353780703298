import { createAction, createReducer } from '@reduxjs/toolkit'
import StorefrontCategory from '@local-types/categories/storefrontCategory'
import { AppThunk } from 'store'
import categories from 'apiService/categories'

interface FetchCategoryFulfilled {
	category: StorefrontCategory | null
}

export const fetchCategoryPending = createAction('fetch-category-pending')
export const fetchCategoryFulfilled = createAction<FetchCategoryFulfilled>('fetch-category-fulfilled')
export const fetchCategoryFailed = createAction('fetch-category-failed')
export const fetchCategoryBySlug = (slug: string): AppThunk => async dispatch => {
	try {
		dispatch(fetchCategoryPending())
		const res = await categories.get({ slugs: [slug] })
		dispatch(fetchCategoryFulfilled({ category: res.data.length > 0 ? res.data[0] : null }))
	} catch (err) {
		dispatch(fetchCategoryFailed())
	}
}

export interface CatReducer {
	fetchCategoryPending: boolean
	fetchCategoryFailed: boolean
	bySlug: {
		[slug: string]: StorefrontCategory
	}
}

const initialState: CatReducer = {
	fetchCategoryPending: false,
	fetchCategoryFailed: false,
	bySlug: {},
}

const catReducer = createReducer(initialState, builder =>
	builder
		.addCase(fetchCategoryPending, store => {
			store.fetchCategoryPending = true
			store.fetchCategoryFailed = false
		})
		.addCase(fetchCategoryFulfilled, (store, action) => {
			store.fetchCategoryPending = false
			store.fetchCategoryFailed = action.payload.category !== null
			if (action.payload.category) store.bySlug[action.payload.category.slug] = action.payload.category
		})
		.addCase(fetchCategoryFailed, store => {
			store.fetchCategoryPending = false
			store.fetchCategoryFailed = true
		})
)

export default catReducer
