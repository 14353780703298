import React, { FC } from 'react'
import * as StyledHeaderLists from './headerLists.styles'
import IconHeart from 'icons/heart.svg'
import Link from 'next/link'

export const HeaderLists: FC = () => {
	return (
		<Link href="/minar-sidur/listar">
			<StyledHeaderLists.Wrapper href="/minar-sidur/listar">
				<IconHeart />
			</StyledHeaderLists.Wrapper>
		</Link>
	)
}
