import { Auth } from 'aws-amplify'
import { useDispatch } from 'react-redux'
import { SessionValidationResult, validateCurrentSession } from '../../helpers/authHelpers/authHelpers'
import { fetchUserInfo, setAuthUser } from '../../reducers/authReducer'

export const useAuthentication = () => {
	const dispatch = useDispatch()

	const verifySession = async () => {
		const session = validateCurrentSession()
		if (session === SessionValidationResult.Invalid) return
		const user = await Auth.currentUserInfo()
		if (user) {
			dispatch(
				setAuthUser({
					authState: 'pending',
					user: user.attributes,
				})
			)
			dispatch(fetchUserInfo())
		} else {
			dispatch(
				setAuthUser({
					authState: 'notLoggedIn',
					user: null,
				})
			)
		}
	}
	return { verifySession }
}
