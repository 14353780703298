import React, { FC } from 'react'
import * as StyledMobileMenu from './mobileMenu.styles'
import { useTrail, useSpring } from 'react-spring'
import { StorefrontCategoryTreeItem } from '@local-types/categoryTrees/storefrontCategoryTree'
import useMeasure from 'hooks/useMeasure'
import Link from 'next/link'

interface Props {
	active: boolean
	category: StorefrontCategoryTreeItem
	onItemClick: (path: string[]) => void
}

const SubMenu: FC<Props> = ({ active, category, onItemClick }) => {
	const { bind, bounds } = useMeasure()
	const hasSubCategories = !!category.children.length

	const allInSubCategory: StorefrontCategoryTreeItem = {
		id: '',
		categoryId: '',
		slug: '',
		name: 'Sjá allar vörur',
		description: '',
		children: [],
	}
	const subCategories = hasSubCategories ? category.children.concat([allInSubCategory]) : []

	const props = useSpring({
		from: { height: 0 },
		to: { height: active ? bounds.height : 0 },
	})

	const trail = useTrail(subCategories.length, {
		config: { mass: 5, tension: 4000, friction: 200 },
		from: { transform: 'translate3d(-5%, 0, 0)', opacity: 0 },
		to: {
			transform: active ? 'translate3d(0%, 0, 0)' : 'translate3d(-5%, 0, 0)',
			opacity: active ? 1 : 0,
		},
	})

	return (
		<StyledMobileMenu.SubMenu style={props}>
			<div {...bind}>
				{trail.map((props, index) => {
					const item = subCategories[index]
					return (
						<Link
							href={`/vorur?flokkar=${category?.slug ? `${category.slug}` : ''}${item.slug ? `;${item.slug}` : ''}`}
							key={item.id}>
							<StyledMobileMenu.SubMenuItem
								href={`/vorur?flokkar=${category?.slug ? `${category.slug}` : ''}${item.slug ? `;${item.slug}` : ''}`}
								style={props}
								onClick={() => onItemClick([category?.categoryId || '', item.categoryId])}>
								{item.name}
							</StyledMobileMenu.SubMenuItem>
						</Link>
					)
				})}
			</div>
		</StyledMobileMenu.SubMenu>
	)
}

export default SubMenu
