import styled from 'styled-components'
import { animated } from 'react-spring'
import { withFancyScrollbar } from 'helpers/styleHelpers/styleHelpers'
import { fadeIn } from 'constants/keyframes/fades'

export const Wrapper = styled(animated.div)`
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	background: #ffffff;
	box-shadow: 0px 9px 24px rgba(0, 0, 0, 0.11);
	overflow: hidden;
`

export const Overlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: -1;
	background-color: rgba(0, 0, 0, 0.2);
	opacity: 0;
	animation: ${fadeIn} 400ms linear forwards;
`

export const Inner = styled.div`
	position: relative;
	max-height: 42.7rem;
	overflow-y: scroll;
	${withFancyScrollbar};
`

export const SpinnerWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	z-index: 3;
	width: 100%;
	display: flex;
	justify-content: center;
	padding: 1.5rem;
	background-color: #fff;
	opacity: 0;
	animation: ${fadeIn} 400ms linear forwards;
	animation-delay: 400ms;
`
export const CategoryResultLink = styled.a`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 2rem 2.5rem;
	color: ${({ theme }) => theme.colors.textColor};
	font-weight: 400;
	border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
	transition: background-color 200ms;
	cursor: pointer;

	&:hover {
		background-color: ${({ theme }) => theme.colors.grayLighter};
	}
`

export const MenuBottomLink = styled.a`
	position: sticky;
	bottom: 0;
	z-index: 2;
	display: flex;
	justify-content: flex-end;
	width: 100%;
	padding: 2rem 2.5rem;
	color: ${({ theme }) => theme.colors.textDark};
	border-top: 1px solid ${({ theme }) => theme.colors.borderColor};
	background-color: #fff;
	font-weight: 700;
	transition: background-color 200ms;
	cursor: pointer;

	&:hover {
		background-color: ${({ theme }) => theme.colors.grayLighter};
	}
`

export const MenuBottomIconWrapper = styled.div`
	margin-right: 1.5rem;
	transform: translateY(2px);
`
