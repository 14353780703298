import React, { FC } from 'react'
import * as StyledFooter from './footer.styles'
import { PageContainerLarge, Columns, Column } from '../base/grid/grid'
import BasicInfoPage from 'interfaces/prismic/basicInfo'
// @ts-ignore
import { RichText } from 'prismic-reactjs'
import { Block } from 'components/base/sections/section'
import useFilters from 'hooks/useFilters'
import Link from 'next/link'
import { RichTextStyleWrapper } from 'components/base/typography/richTextStyleWrapper'
import FbIcon from 'icons/social-fb.svg'
import InstaIcon from 'icons/social-insta.svg'
import YtIcon from 'icons/social-yt.svg'
import theme from 'constants/theme'
import { cookiehub } from 'helpers/analyticsHelpers/analyticsHelpers'

interface Props {
	basicInfo: BasicInfoPage
}

const Footer: FC<Props> = ({ basicInfo }) => {
	const { filters, setMainCategoryTreePath } = useFilters()

	const onNavLinkClick = (path: string[]) => {
		setMainCategoryTreePath(path)
	}

	const onCookieSettingsClick = () => {
		if (cookiehub) cookiehub.openSettings()
	}

	return (
		<StyledFooter.Wrapper>
			<StyledFooter.Inner>
				<PageContainerLarge>
					<Columns>
						<Column tablet={1 / 2} desktopSmall={1 / 4}>
							<StyledFooter.LogoBannersWrapper>
								<img src="/images/logo-banners.png" alt="Kennimerki Holta, SS og Hollt og Gott" />
							</StyledFooter.LogoBannersWrapper>
							<Block>
								<Block mb="2rem">
									<StyledFooter.MainHeading mb="0" color="white">
										Matarstræti
									</StyledFooter.MainHeading>
									<div>{RichText.asText(basicInfo.data.address)}</div>
								</Block>
								<Block mb="2rem">
									<StyledFooter.SsnWrapper>
										<span>Sláturfélag Suðurlands</span>
										{`Kt: ${RichText.asText(basicInfo.data.ss_ssn)}`}
									</StyledFooter.SsnWrapper>
									<StyledFooter.SsnWrapper>
										<span>Holta</span>
										{`Kt: ${RichText.asText(basicInfo.data.holta_ssn)}`}
									</StyledFooter.SsnWrapper>
									<StyledFooter.SsnWrapper>
										<span>Hollt og Gott</span>
										{`Kt: ${RichText.asText(basicInfo.data.hollt_og_gott_ssn)}`}
									</StyledFooter.SsnWrapper>
								</Block>
								<StyledFooter.FooterLargeAnchor href={`tel:${RichText.asText(basicInfo.data.phone_number)}`}>
									Sími: {RichText.asText(basicInfo.data.phone_number)}
								</StyledFooter.FooterLargeAnchor>
								<div>
									<span>Netfang: </span>
									<StyledFooter.FooterAnchor
										color={theme.colors.anchorBlue}
										href={`mailto:${RichText.asText(basicInfo.data.email)}`}
										underline>
										{RichText.asText(basicInfo.data.email)}
									</StyledFooter.FooterAnchor>
								</div>
							</Block>
						</Column>
						<Column tablet={1 / 2} desktopSmall={1 / 4}>
							<StyledFooter.ColumnHeading color="white">Opnunartímar</StyledFooter.ColumnHeading>
							<RichTextStyleWrapper>{RichText.render(basicInfo.data.opening_hours)}</RichTextStyleWrapper>
						</Column>
						<StyledFooter.FlexColumn tablet={1 / 2} desktopSmall={1 / 4}>
							<StyledFooter.ColumnHeading color="white">Vöruflokkar</StyledFooter.ColumnHeading>
							{filters?.mainCategoryTree?.children.map(treeItem => (
								<Block mb="1rem" key={treeItem.id}>
									<Link href={`/vorur?flokkar=${encodeURI(treeItem.slug)}`}>
										<StyledFooter.FooterAnchor
											href={`/vorur?flokkar=${encodeURI(treeItem.slug)}`}
											onClick={onNavLinkClick.bind(null, [treeItem.categoryId])}>
											{treeItem.name}
										</StyledFooter.FooterAnchor>
									</Link>
								</Block>
							))}
						</StyledFooter.FlexColumn>
						<StyledFooter.FlexColumn tablet={1 / 2} desktopSmall={1 / 4}>
							<StyledFooter.ColumnHeading color="white">Vefverslun</StyledFooter.ColumnHeading>
							<Block mb="1rem">
								<Link href="/skilmalar">
									<StyledFooter.FooterAnchor href="/skilmalar">Skilmálar</StyledFooter.FooterAnchor>
								</Link>
							</Block>
							<Block mb="1rem">
								<Link href="/sendingarupplysingar">
									<StyledFooter.FooterAnchor href="/sendingarupplysingar">
										Sendingarupplýsingar
									</StyledFooter.FooterAnchor>
								</Link>
							</Block>
							<Block mb="1rem">
								<Link href="/personuverndarstefna">
									<StyledFooter.FooterAnchor href="/personuverndarstefna">
										Persónuverndarstefna
									</StyledFooter.FooterAnchor>
								</Link>
							</Block>
							<Block mb="1rem">
								<StyledFooter.FooterAnchor onClick={onCookieSettingsClick}>
									Stillingar á vafrakökum
								</StyledFooter.FooterAnchor>
							</Block>
							<StyledFooter.SocialMediaWrapper>
								<StyledFooter.FooterAnchor
									aria-label="YouTube hlekkur"
									href={RichText.asText(basicInfo.data.youtube_url)}>
									<YtIcon />
								</StyledFooter.FooterAnchor>
								<StyledFooter.FooterAnchor
									aria-label="Facebook hlekkur"
									href={RichText.asText(basicInfo.data.facebook_url)}>
									<FbIcon />
								</StyledFooter.FooterAnchor>
								<StyledFooter.FooterAnchor
									aria-label="Instagram hlekkur"
									href={RichText.asText(basicInfo.data.instagram_url)}>
									<InstaIcon />
								</StyledFooter.FooterAnchor>
							</StyledFooter.SocialMediaWrapper>
						</StyledFooter.FlexColumn>
					</Columns>
				</PageContainerLarge>
			</StyledFooter.Inner>
		</StyledFooter.Wrapper>
	)
}

export default Footer
