import React, { FC, useState } from 'react'
import * as StyledLogo from './logo.styles'
import useNextRouterEvents from '../../hooks/useNextRouterEvents'
import styled, { keyframes, css } from 'styled-components'

const rotate = keyframes`
	0% { transform: rotateY(0deg)}
	100% { transform: rotateY(360deg)}
`

interface NextTransform {
	rotateLarge?: number
	rotateSmall?: number
}

interface StyledPathProps {
	routing: boolean
	animationDelay: string
}

const StyledPath = styled.path<StyledPathProps>`
	transform-origin: center;

	${({ routing, animationDelay }) =>
		routing &&
		css`
			animation: ${rotate} 2s linear infinite;
			animation-delay: ${animationDelay};
		`}
`

const Logo: FC<{}> = () => {
	const [routing, setRouting] = useState(false)

	const onRouteChangeStart = () => {
		setRouting(true)
	}
	const onRouteChangeEnd = () => {
		setRouting(false)
	}

	useNextRouterEvents(onRouteChangeStart, onRouteChangeEnd, onRouteChangeEnd)

	return (
		<StyledLogo.Wrapper>
			<svg width="30" height="37" viewBox="0 0 30 37" fill="none">
				<path d="M17.3148 31L13.5185 32V37H17.6852L17.3148 31Z" fill="#05405B" />
				<path d="M17.3148 11.8995L14.1667 12.3643V14.6884H17.3148V11.8995Z" fill="#05405B" />
				<StyledPath
					d="M6.75926 11.0628L22.1296 10.598V4.27638H6.75926L0 6.8794L6.75926 11.0628Z"
					fill="#4BADC2"
					routing={routing}
					animationDelay="0s"
				/>
				<path d="M16.8519 2.88191H14.1667L14.537 0H16.8519V2.88191Z" fill="#05405B" />
				<StyledPath
					d="M9.97071 30L19 29.726V26H9.97071L6 27.5342L9.97071 30Z"
					fill="#838A93"
					routing={routing}
					animationDelay="0.8s"
				/>
				<path d="M17 25H14L14.4138 23H17V25Z" fill="#05405B" />
				<StyledPath
					d="M25.0185 16.6508L9 16V21.5779H25.0185L29.2778 18.9749L25.0185 16.6508Z"
					fill="#BB5366"
					routing={routing}
					animationDelay="1.2s"
				/>
			</svg>
			<StyledLogo.LogoText>Matarstræti</StyledLogo.LogoText>
		</StyledLogo.Wrapper>
	)
}

export default Logo
