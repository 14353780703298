import { animated } from 'react-spring'
import styled from 'styled-components'
import { zIndex } from '../../../constants/zIndex/zIndex'
import { H3 } from 'components/base/typography/headings'
import Button from 'components/base/buttons/buttons'
import { withFancyScrollbar } from 'helpers/styleHelpers/styleHelpers'
import { media } from 'constants/media/media'

export const Wrapper = styled(animated.div)`
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	background: #fff;
	z-index: ${zIndex.catNavMenu};
	overflow: hidden;

	${media.desktopLarge`
		width: 106rem;
	`}
`

export const MenuInner = styled.div`
	display: flex;
	position: relative;
	width: 100%;
`

export const MenuItems = styled.div`
	display: flex;
	flex-direction: column;
	width: 37%;
	padding: 3rem 0;
`

export const MenuItemArrowWrapper = styled.div`
	position: absolute;
	right: 3rem;
	font-size: 1.6rem;
	line-height: 1;
	color: ${({ theme }) => theme.colors.secondary};
	transform: translate3d(-50%, 0, 0);
	opacity: 0;
	transition: transform 200ms, opacity 200ms;
`

export const MenuItem = styled.a`
	display: flex;
	align-items: center;
	position: relative;
	padding: 1.5rem 6rem;
	font-size: 2.8rem;
	font-weight: 800;
	color: ${({ theme }) => theme.colors.textDark};
	cursor: pointer;

	&:hover {
		${MenuItemArrowWrapper} {
			transform: translate3d(0, 0, 0);
			opacity: 1;
			transition-delay: 50ms;
		}
	}
`

export const SubMenuItems = styled.div`
	display: flex;
	flex-direction: column;
	width: 31%;
	max-height: 70vh;
	padding: 3rem 0;
	border-left: 1px solid ${({ theme }) => theme.colors.borderColor};
	overflow-y: auto;
	overflow-x: hidden;
	${withFancyScrollbar};
`

export const SubMenuTransitionWrapper = styled(animated.div)``

export const SubMenuItem = styled.a`
	display: flex;
	width: 100%;
	font-size: 1.6rem;
	padding: 1.2rem 4.5rem;
	font-weight: 500;
	color: ${({ theme }) => theme.colors.textColor};
	cursor: pointer;
	transition: color;

	&:hover {
		color: ${({ theme }) => theme.colors.primary};
	}
`
export const MenuCta = styled.div`
	width: 32%;
	background-color: ${({ theme }) => theme.colors.grayLighter};
`

interface ImageProps {
	image: string
}

export const MenuCtaImage = styled.div<ImageProps>`
	padding-top: 76%;
	background-size: cover;
	background-position: 50% 50%;
	background-image: url(${({ image }) => image});
`

export const MenuCtaContent = styled.div`
	display: flex;
	flex-direction: column;
	padding: 3.6rem 4.4rem;
`

export const MenuCtaTitle = styled(H3)`
	margin-bottom: 3rem;

	${media.desktopLarge`
		font-size: 2.6rem;
		max-width: 22rem;
	`}
`

export const MenuCtaButton = styled(Button)`
	color: ${({ theme }) => theme.colors.textDark};
`
