import React, { FC, useState, useRef } from 'react'
import { useSpring, useTrail } from 'react-spring'
import * as StyledCatNavMenu from './catNavMenu.styles'
import useTypedSelector from '../../../interfaces/useTypedSelector'
import useMeasure from '../../../hooks/useMeasure'
import Link from 'next/link'
import { useDispatch } from 'react-redux'
import { toggleCatNavVisibility } from '../../../reducers/catNavReducer'
import { StorefrontCategoryTreeItem } from '@local-types/categoryTrees/storefrontCategoryTree'
import useCategoryTree from 'hooks/useCategoryTree'
import { FaArrowRight } from 'react-icons/fa'
import useFilters from 'hooks/useFilters'
import Menus from 'interfaces/prismic/menus'
// @ts-ignore
import { RichText } from 'prismic-reactjs'

interface Props {
	menus: Menus
}

const CatNavMenu: FC<Props> = ({ menus }) => {
	const dispatch = useDispatch()
	const timerRef = useRef()
	const [debouncedHoverId, setDebouncedHoverId] = useState('')
	const { categoryTree } = useCategoryTree()
	const { catNavActive } = useTypedSelector(({ catNav }) => catNav)
	const { setMainCategoryTreePath, clearFilters } = useFilters()
	const { bind, bounds } = useMeasure()

	const menuProps = useSpring({
		height: catNavActive ? (bounds.height ? bounds.height : 0) : 0,
	})
	const activeTopCategory = categoryTree?.children.find(x => x.id === debouncedHoverId)
	const allInSubCategory: StorefrontCategoryTreeItem = {
		id: '',
		categoryId: '',
		slug: '',
		name: 'Sjá allar vörur',
		description: '',
		children: [],
	}

	const subItemChildren = activeTopCategory?.children.concat([allInSubCategory])
	const subMenuItemTransitions = useTrail(subItemChildren?.length || 0, {
		config: { mass: 5, tension: 4000, friction: 200 },
		from: { transform: 'translate3d(-5%, 0, 0)', opacity: 0 },
		to: { transform: 'translate3d(0%, 0, 0)', opacity: 1 },
	})

	const onNavLinkClick = (path?: string[]) => {
		clearFilters()
		dispatch(toggleCatNavVisibility(false))

		setMainCategoryTreePath(path?.filter(x => x !== '') || [])
	}

	const onTopLinkMouseOver = (id: string) => {
		if (debouncedHoverId === '') {
			setDebouncedHoverId(id)
		} else {
			// @ts-ignore
			timerRef.current = setTimeout(() => setDebouncedHoverId(id), 200)
		}
	}
	const onTopLinkMouseLeave = () => {
		if (timerRef.current) clearTimeout(timerRef.current)
	}

	return (
		<StyledCatNavMenu.Wrapper style={menuProps}>
			<StyledCatNavMenu.MenuInner {...bind}>
				<StyledCatNavMenu.MenuItems>
					{categoryTree && (
						<>
							<Link href="/vorur">
								<StyledCatNavMenu.MenuItem
									href="/vorur"
									onClick={() => onNavLinkClick()}
									onMouseEnter={() => onTopLinkMouseOver('')}
									onMouseLeave={onTopLinkMouseLeave}>
									Allar vörur
									<StyledCatNavMenu.MenuItemArrowWrapper>
										<FaArrowRight />
									</StyledCatNavMenu.MenuItemArrowWrapper>
								</StyledCatNavMenu.MenuItem>
							</Link>
							{categoryTree.children.map(item => (
								<Link href={`/vorur?flokkar=${item.slug}`} key={item.id}>
									<StyledCatNavMenu.MenuItem
										href={`/vorur?flokkar=${item.slug}`}
										onClick={onNavLinkClick.bind(null, [item.categoryId])}
										onMouseEnter={onTopLinkMouseOver.bind(null, item.id)}
										onMouseLeave={onTopLinkMouseLeave}>
										{item.name}
										<StyledCatNavMenu.MenuItemArrowWrapper>
											<FaArrowRight />
										</StyledCatNavMenu.MenuItemArrowWrapper>
									</StyledCatNavMenu.MenuItem>
								</Link>
							))}
						</>
					)}
				</StyledCatNavMenu.MenuItems>
				<StyledCatNavMenu.SubMenuItems>
					{subMenuItemTransitions.map((props, index) => {
						const child = subItemChildren?.[index]
						if (!child) return null
						return (
							<StyledCatNavMenu.SubMenuTransitionWrapper key={child.id} style={props}>
								<Link
									href={`/vorur?flokkar=${activeTopCategory?.slug ? `${activeTopCategory.slug}` : ''}${
										child.slug ? `;${child.slug}` : ''
									}`}>
									<StyledCatNavMenu.SubMenuItem
										href={`/vorur?flokkar=${activeTopCategory?.slug ? `${activeTopCategory.slug}` : ''}${
											child.slug ? `;${child.slug}` : ''
										}`}
										onClick={onNavLinkClick.bind(null, [activeTopCategory?.categoryId || '', child.categoryId])}>
										{child.name}
									</StyledCatNavMenu.SubMenuItem>
								</Link>
							</StyledCatNavMenu.SubMenuTransitionWrapper>
						)
					})}
				</StyledCatNavMenu.SubMenuItems>
				<StyledCatNavMenu.MenuCta>
					<StyledCatNavMenu.MenuCtaImage image={menus.data.category_menu_image.url} />
					<StyledCatNavMenu.MenuCtaContent>
						<StyledCatNavMenu.MenuCtaTitle>
							{RichText.asText(menus.data.category_menu_title)}
						</StyledCatNavMenu.MenuCtaTitle>
						<Link href={RichText.asText(menus.data.category_menu_link)}>
							<StyledCatNavMenu.MenuCtaButton as="a" href={RichText.asText(menus.data.category_menu_link)}>
								{RichText.asText(menus.data.category_menu_link_text)}
							</StyledCatNavMenu.MenuCtaButton>
						</Link>
					</StyledCatNavMenu.MenuCtaContent>
				</StyledCatNavMenu.MenuCta>
			</StyledCatNavMenu.MenuInner>
		</StyledCatNavMenu.Wrapper>
	)
}

export default CatNavMenu
