import { configureStore, Action } from '@reduxjs/toolkit'
import rootReducer from './reducers'
import { ThunkAction } from 'redux-thunk'
export const store = configureStore({ reducer: rootReducer })
export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>

export const initStore = (initialState: RootState) => {
	return configureStore({ reducer: rootReducer, preloadedState: initialState })
}
