import styled, { css } from 'styled-components'
import { fadeIn } from 'constants/keyframes/fades'
import { media } from 'constants/media/media'

interface WrapperProps {
	active: boolean
}

export const Wrapper = styled.div<WrapperProps>`
	position: relative;
	margin-left: auto;

	${({ active }) =>
		active &&
		css`
			z-index: 2;
		`}
`

interface LoginTriggerProps {
	active: boolean
}

export const LoginTrigger = styled.button<LoginTriggerProps>`
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 0 0 auto;
	height: 100%;
	width: 6rem;
	background-color: #fff;
	cursor: pointer;
	transition: background-color 300ms ease, box-shadow 300ms ease;
	svg path {
		transition: fill 300ms;
		fill: ${({ theme }) => theme.colors.textDark};
	}
	&:hover {
		svg path {
			fill: ${({ theme }) => theme.colors.secondary};
		}
	}
	${media.desktopSmall`
		width: 8.1rem;
		background-color: ${({ theme }) => theme.colors.grayLightest};

		&:hover {
			background-color: ${({ theme }) => theme.colors.grayLight};
		}
	`}

	${({ active, theme }) =>
		active &&
		css`
			background-color: #fff;
			box-shadow: 0px 14px 14px rgba(0, 0, 0, 0.15);

			${media.desktopSmall`
				background-color: #fff;

				&:hover { background-color: #fff; }
			`}

			svg path {
				fill: ${theme.colors.secondary};
			}

			&:hover {
				background-color: #fff;
			}
		`}
`

export const Overlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: -1;
	background-color: rgba(0, 0, 0, 0.2);
	opacity: 0;
	animation: ${fadeIn} 300ms ease forwards;
`

interface LoginMenuProps {
	active: boolean
}

export const LoginMenu = styled.div<LoginMenuProps>`
	display: none;
	position: absolute;
	top: 100%;
	right: 0;
	width: 30.6rem;
	background-color: #fff;
	box-shadow: 0px 14px 14px rgba(0, 0, 0, 0.15);
	opacity: 0;
	animation: ${fadeIn} 300ms ease forwards;

	@media (max-width: 360px) {
		right: -17vw;
	}

	${({ active }) =>
		active &&
		css`
			display: block;
		`}
`

export const LoginMenuForm = styled.form`
	padding: 3.5rem 3rem 1.5rem 3rem;
`

export const LoginMenuFooter = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 2rem 3rem;
	border-top: 1px solid ${({ theme }) => theme.colors.borderColor};
`
