import React, { FC } from 'react'
import styled from 'styled-components'
import { Spinner } from 'components/base/spinner/spinner'

const StyledLoader = styled.div`
	display: flex;
	align-items: center;
	padding: 0 3rem;
`

const HeaderLoginLoader: FC<{}> = () => {
	return (
		<StyledLoader>
			<Spinner size="2rem" />
		</StyledLoader>
	)
}

export default HeaderLoginLoader
