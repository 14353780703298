import styled, { css } from 'styled-components'
import { animated } from 'react-spring'
import { fadeIn } from 'constants/keyframes/fades'
import { media } from 'constants/media/media'

interface UserMenuProps {
	active: boolean
}

export const Wrapper = styled.div<UserMenuProps>`
	position: relative;
	margin-left: auto;
	z-index: 2;
`
export const CicledInitial = styled.div`
	background-color: ${({ theme }) => theme.colors.secondary};
	width: 1.5rem;
	height: 1.5rem;
	display: none;
	justify-content: center;
	align-items: center;
	font-size: 0.7rem;
	color: white;
	font-weight: 700;
	border-radius: 100%;
	height: 3.6rem;
	width: 3.6rem;
	font-size: 2.4rem;
	${media.tablet`
		display: flex;
	`}
`

export const Overlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: -1;
	background-color: rgba(0, 0, 0, 0.2);
	opacity: 0;
	animation: ${fadeIn} 300ms ease forwards;
	${media.mobileOnly`
		display: none;
	`}
`

export const MenuTrigger = styled.div<UserMenuProps>`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	line-height: 100%;
	font-size: 1.8rem;
	font-weight: 600;
	color: ${({ theme }) => theme.colors.textDark};
	cursor: pointer;
	transition: background-color 200ms;
	-webkit-tap-highlight-color: transparent;
	background-color: ${({ theme, active }) => (active ? theme.colors.white : theme.colors.grayLightest)};

	${media.tablet<UserMenuProps>`
		padding: 0 1rem;
		background-color: ${({ theme, active }) => (active ? theme.colors.grayLightest : theme.colors.white)};
	`}

	${media.desktopLarge`
		justify-content: flex-start;
		width: auto;
		min-width: 10rem;
    	padding: 0;

		&:hover {
			background-color: ${({ theme }) => theme.colors.grayLighter};
		}
	`}

	${media.mobileOnly<UserMenuProps>`
		background: none;
		z-index: -1;
		background-color: ${({ theme }) => theme.colors.white};
	`}
`

export const UserIconWrapper = styled.div<UserMenuProps>`
	svg {
		${media.tablet`
			display: none;
		`}
	}
	svg path {
		fill: ${({ active, theme }) => (active ? theme.colors.secondary : theme.colors.textDark)};
		transition: fill 300ms;
	}
	&:hover {
		svg path {
			fill: ${({ theme }) => theme.colors.secondary};
		}
	}
	padding: 0;
	${media.tablet`
		padding: 0 1.2rem;
	`}

	${media.desktopLarge`
		padding: 0 0.8rem 0 2.7rem;
	`}
`

export const UserDisplayName = styled.div`
	white-space: nowrap;
	display: none;

	${media.desktopLarge`
    	display: flex;
   		font-size: 2rem;
	`}
`

export const ChrevonIconWrapper = styled.div`
	display: none;
	padding-left: 1rem;
	margin-left: auto;
	svg path {
		stroke: ${({ theme }) => theme.colors.textColor};
	}
	${media.desktopLarge`
		display: flex;
		padding: 0 3rem 0 4.2rem;

	`}
`

export const MobileScreenFill = styled.div`
	display: none;
	height: 100vh;
	background-color: ${({ theme }) => theme.colors.grayLightest};
	${media.mobileOnly`
		display: block;
	`}
`

export const UserMenu = styled(animated.div)`
	position: absolute;
	top: 100%;
	right: 0;
	width: 25rem;
	overflow: hidden;
	box-shadow: 0px 14px 14px rgba(0, 0, 0, 0.15);
	height: 100vh;

	${media.desktopLarge`
		width: 100%;
	`}
	${media.tablet`
		min-width: 30rem;
	`}
	${media.mobileOnly`
		width: 36rem;
		background-color: rgb(255, 255, 255);
		position: fixed;
		margin-left: auto;
		margin-right: auto;
		right: 0px;
		top: 6rem;
		left: 0px;
		width: 100%;
		height: 0px;
		z-index: -1;
		z-index: 1;
	`}
`

export const UserMenuInner = styled.div`
	position: relative;
`

export const ServicePortalNavigation = styled.div`
	padding: 1.5rem 3rem;
	background-color: ${({ theme }) => theme.colors.grayLightest};
	${media.desktopLarge`
		padding-top: 3rem;
	`}
	${media.mobileOnly`
		font-size: 1.6rem;
		line-height: 2.1rem;
		padding: 1.5rem 4rem;
    	padding-top: 1.8rem;
	`}
`

export const ServicePortalLink = styled.a`
	display: block;
	padding: 0.3rem 0;
	color: ${({ theme }) => theme.colors.textDark};
	font-weight: 600;
	transition: color 250ms;
	&:hover {
		color: ${({ theme }) => theme.colors.primary};
	}
	${media.mobileOnly`
		padding: 1.2rem 0rem;
	`}
`

interface ControlButtonProps {
	colorTheme: 'dark' | 'light'
}

export const ControlButton = styled.button<ControlButtonProps>`
	display: flex;
	align-items: center;
	width: 100%;
	height: 4.8rem;
	font-family: ${({ theme }) => theme.fonts.fontFamily};
	font-weight: 500;
	font-size: 1.4rem;
	padding: 0 3rem;
	transition: background-color 200ms;
	cursor: pointer;

	svg {
		margin-left: auto;
	}

	${({ colorTheme }) =>
		colorTheme === 'light' &&
		css`
			background-color: #fff;
			color: ${({ theme }) => theme.colors.secondary};

			&:hover {
				background-color: ${({ theme }) => theme.colors.grayLight};
			}
			${media.mobileOnly`
				background-color: ${({ theme }) => theme.colors.grayLightest};
			`}
		`}

	${({ colorTheme }) =>
		colorTheme === 'dark' &&
		css`
			background-color: ${({ theme }) => theme.colors.textDark};
			color: #fff;

			&:hover {
				background-color: #061e24;
			}
		`}
	
	${media.mobileOnly`
		padding: 0rem 4rem;
	`}
`
