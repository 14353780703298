import styled from 'styled-components'
import { animated } from 'react-spring'
import { withFancyScrollbar } from 'helpers/styleHelpers/styleHelpers'
import { zIndex } from 'constants/zIndex/zIndex'
import { media } from 'constants/media/media'
import { fadeIn } from 'constants/keyframes/fades'

export const Wrapper = styled(animated.aside)`
	position: fixed;
	z-index: ${zIndex.basketSidebar};
	height: ${({ theme }) => `calc(100% - ${theme.sizes.headerMobile})`};
	width: 100%;
	max-width: 37.5rem;
	top: ${({ theme }) => theme.sizes.headerMobile};
	right: 0;
	background-color: ${({ theme }) => theme.colors.white};
	box-shadow: 0px 14px 14px rgba(0, 0, 0, 0.15);
	${withFancyScrollbar};

	${media.desktopSmall`
		height: ${({ theme }) => `calc(100% - ${theme.sizes.headerDesktop})`};
		top: ${({ theme }) => theme.sizes.headerDesktop};
	`}

	${media.mobileOnly`
		height: ${({ theme }) => `calc(100% - ${theme.sizes.headerMobile} - ${theme.sizes.mobileSearch})`};
		top: ${({ theme }) => `calc(${theme.sizes.headerMobile} + ${theme.sizes.mobileSearch})`};
		max-width: 100%;
	`}

	${media.tabletOnly`
		height: ${({ theme }) => `calc(100% - ${theme.sizes.headerDesktop} - ${theme.sizes.mobileSearch})`};
		top: ${({ theme }) => `calc(${theme.sizes.headerDesktop} + ${theme.sizes.mobileSearch})`};
	`}
`

export const BasketOverlay = styled.div`
	position: fixed;
	z-index: ${zIndex.basketSidebar - 1};
	height: ${({ theme }) => `calc(100% - ${theme.sizes.headerMobile})`};
	width: 100%;
	top: ${({ theme }) => theme.sizes.headerMobile};
	left: 0;
	background-color: rgba(0, 0, 0, 0.3);
	opacity: 0;
	animation: ${fadeIn} 400ms linear forwards;

	${media.tablet`
		height: calc(100% - 8rem);
		top: 8rem;
	`}
`
