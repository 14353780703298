import { Auth } from 'aws-amplify'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
	INACTIVE_USER_TIME_THRESHOLD,
	USER_ACTIVITY_THROTTLER_TIME,
} from 'constants/inactivityTracking/sessionInactivityThreshold'
import { setInLocalStorage } from 'helpers/storage/storage'
import { setAuthUser, setUserInfo } from 'reducers/authReducer'
import { SessionValidationResult, validateCurrentSession } from 'helpers/authHelpers/authHelpers'
import useTypedSelector from 'interfaces/useTypedSelector'
import { fetchBasket } from 'reducers/basketReducer'

export const useInactivityTracking = () => {
	const dispatch = useDispatch()
	const { authState } = useTypedSelector(({ auth }) => auth)
	let userActivityTimeout: number | null = null
	let userActivityThrottlerTimeout: number | null = null
	let isInactive = false

	const logoutUser = async () => {
		await Auth.signOut()
		dispatch(
			setAuthUser({
				authState: 'notLoggedIn',
				user: null,
			})
		)
		dispatch(setUserInfo({ userInfo: null }))
		dispatch(fetchBasket('', []))
	}

	const inactiveUserAction = async () => {
		if (authState === 'notLoggedIn') return
		isInactive = true
		logoutUser()
	}

	const resetUserActivityTimeout = () => {
		clearTimeout(userActivityTimeout as number)
		userActivityTimeout = setTimeout(() => {
			inactiveUserAction()
		}, INACTIVE_USER_TIME_THRESHOLD)
	}

	const userActivityThrottler = () => {
		if (isInactive) {
			isInactive = false
			resetUserActivityTimeout()
		}

		if (!userActivityThrottlerTimeout) {
			userActivityThrottlerTimeout = setTimeout(() => {
				resetUserActivityTimeout()

				if (userActivityThrottlerTimeout == null) return
				clearTimeout(userActivityThrottlerTimeout as number)
				userActivityThrottlerTimeout = null
			}, USER_ACTIVITY_THROTTLER_TIME)
		}
	}

	const deactivateTracking = () => {
		// eslint-disable-next-line @typescript-eslint/no-use-before-define
		window.removeEventListener('beforeunload', handleBeforeUnload)
		window.removeEventListener('mousemove', userActivityThrottler)
		window.removeEventListener('scroll', userActivityThrottler)
		window.removeEventListener('keydown', userActivityThrottler)
		window.removeEventListener('resize', userActivityThrottler)
	}

	const setPreviousSessionEndDate = () => {
		setInLocalStorage('previousSessionEndDate', new Date().toString())
	}

	const handleBeforeUnload = () => {
		deactivateTracking()
		setPreviousSessionEndDate()
	}

	const logoutUserIfSessionIsExpired = async () => {
		const result = validateCurrentSession()
		if (result === SessionValidationResult.Invalid) await logoutUser()
	}

	useEffect(() => {
		logoutUserIfSessionIsExpired()
		window.addEventListener('mousemove', userActivityThrottler)
		window.addEventListener('scroll', userActivityThrottler)
		window.addEventListener('keydown', userActivityThrottler)
		window.addEventListener('resize', userActivityThrottler)
		window.addEventListener('beforeunload', handleBeforeUnload)
	}, [])
}
