import React, { FC, useRef, useState, useEffect } from 'react'
import * as StyledMobileMenu from './mobileMenu.styles'
import useTypedSelector from 'interfaces/useTypedSelector'
import useCategoryTree from 'hooks/useCategoryTree'
import { useTrail, useSpring, useChain } from 'react-spring'
import { StorefrontCategoryTreeItem } from '@local-types/categoryTrees/storefrontCategoryTree'
import SubMenu from './subMenu'
import { useDispatch } from 'react-redux'
import { toggleMobileMenuVisibility } from 'reducers/catNavReducer'
import useFilters from 'hooks/useFilters'
import { useRouter } from 'next/router'

const MobileMenu: FC<{}> = () => {
	const dispatch = useDispatch()
	const router = useRouter()
	const [activeMenuItemId, setActiveMenuItemId] = useState('')
	const { mobileMenuActive } = useTypedSelector(({ catNav }) => catNav)
	const { categoryTree } = useCategoryTree()
	const { setMainCategoryTreePath } = useFilters()
	const categories = categoryTree?.children || []
	const menuRef = useRef(null)
	const menuItemsRef = useRef(null)
	const trailLength = categoryTree ? categoryTree.children.length + 1 : 1

	const menuSpring = useSpring({
		ref: menuRef,
		transform: mobileMenuActive ? 'translate3d(0, 0%, 0)' : 'translate3d(0, -120%, 0)',
	})

	const menuItemTrail = useTrail(trailLength, {
		ref: menuItemsRef,
		config: { mass: 5, tension: 4000, friction: 200 },
		from: { transform: 'translate3d(-5%, 0, 0)', opacity: 0 },
		to: {
			transform: mobileMenuActive ? 'translate3d(0%, 0, 0)' : 'translate3d(-5%, 0, 0)',
			opacity: mobileMenuActive ? 1 : 0,
		},
	})

	useChain(mobileMenuActive ? [menuRef, menuItemsRef] : [menuItemsRef, menuRef], [0, 0.6])

	useEffect(() => {
		setActiveMenuItemId('')
	}, [mobileMenuActive])

	const getAllCategories = () => {
		const allProducts = {
			id: 'all',
			categoryId: '',
			slug: '',
			name: 'Allar vörur',
			description: '',
			children: [],
		}
		return [allProducts, ...categories]
	}

	const handleCategoryClick = (path: string[]) => {
		dispatch(toggleMobileMenuVisibility(false))
		setMainCategoryTreePath(path?.filter(x => x !== '') || [])
	}

	const handleCategoryTriggerClick = (cat: StorefrontCategoryTreeItem, hasSubCategories: boolean) => {
		if (!hasSubCategories) {
			cat.id === 'all' ? handleCategoryClick([]) : handleCategoryClick([cat.id])
			router.push('/vorur')
		}
		setActiveMenuItemId(cat.id === activeMenuItemId ? '' : cat.id)
	}

	return (
		<StyledMobileMenu.Wrapper style={menuSpring} active={mobileMenuActive ? 'active' : 'inactive'}>
			{mobileMenuActive && <StyledMobileMenu.DisableBodyScroll />}
			<StyledMobileMenu.MenuCategoryNav>
				{menuItemTrail.map((props, index) => {
					const allCategories = getAllCategories()
					const category = allCategories[index]
					const hasSubCategories = !!category.children.length
					return (
						<StyledMobileMenu.MenuCategory key={category.id} style={props}>
							<StyledMobileMenu.MenuCategoryTrigger
								active={activeMenuItemId === category.id}
								onClick={() => handleCategoryTriggerClick(category, hasSubCategories)}>
								{category.name}
							</StyledMobileMenu.MenuCategoryTrigger>
							<SubMenu
								active={activeMenuItemId === category.id}
								category={category}
								onItemClick={handleCategoryClick}
							/>
						</StyledMobileMenu.MenuCategory>
					)
				})}
			</StyledMobileMenu.MenuCategoryNav>
		</StyledMobileMenu.Wrapper>
	)
}

export default MobileMenu
