import styled, { css } from 'styled-components'
import { zIndex } from '../../constants/zIndex/zIndex'
import { media } from 'constants/media/media'
import { PageContainerLarge } from '../base/grid/grid'

export const Wrapper = styled.header`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background-color: #fff;
	color: ${({ theme }) => theme.colors.textDark};
	z-index: ${zIndex.header};

	/* z-index fix for iOS Safari */
	transform: translateZ(1px);
	-webkit-transform: translateZ(1px);

	${media.tablet`
		height: ${({ theme }) => theme.sizes.headerDesktop};
	`}
`

export const HeaderPageContainerLarge = styled(PageContainerLarge)`
	box-shadow: 0 1px 0 0 ${({ theme }) => theme.colors.borderColor};
`

export const HeaderInner = styled.div`
	display: flex;
	align-items: stretch;
	width: 100%;
	height: ${({ theme }) => theme.sizes.headerMobile};

	${media.tablet`
		height: ${({ theme }) => theme.sizes.headerDesktop};
	`}
`

export const DesktopControls = styled.div`
	display: flex;
	align-items: stretch;
	width: 100%;

	${media.tabletAndMobileOnly`
		display: none;
	`}
`

interface CatNavWrapperProps {
	active: boolean
}

export const CatNavWrapper = styled.div<CatNavWrapperProps>`
	position: static;

	${media.desktopLarge`
		position: relative;
	`}

	${({ active }) =>
		active &&
		css`
			z-index: 3;
		`}
`

export const CatNavTrigger = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	height: 100%;
	padding: 0 4rem 0 8rem;
	${media.desktopLargeOnly`
		padding: 0 4rem 0 4rem;
	`}
	cursor: pointer;
	background-color: #fff;
	font-weight: 800;
	z-index: 2;

	svg {
		margin-left: 1.5rem;
		font-size: 1.8rem;
		transform: translateY(1px);
		color: ${({ theme }) => theme.colors.secondary};
	}
`

export const LogoWrapper = styled.a`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 2.5rem;
	cursor: pointer;
	height: 100%;

	${media.tablet`
		padding: 0 3rem;
	`}
`

export const HeaderSearchWrapper = styled.div`
	flex-grow: 1;
	z-index: 2;
`

interface BrandImageProps {
	ml?: string
	mr?: string
	height?: string
}

export const HeaderBrandImage = styled.a<BrandImageProps>`
	display: none;
	align-items: center;
	padding: 2.3rem 0;
	margin-left: ${({ ml }) => ml || '1.5rem'};
	margin-right: ${({ mr }) => mr || '1.5rem'};

	img {
		height: ${({ height }) => height || '100%'};
	}

	${media.desktopLarge`
		display: flex;
	`}
`

export const MobileControls = styled.div`
	display: flex;
	justify-content: flex-end;

	${media.desktopSmall`
		display: none;
	`}
`

export const HamburgerWrapper = styled.div`
	padding: 2.1rem 0;
	padding-right: 2.8rem;

	${media.tablet`
		padding: 3.1rem;
	`}
`

export const MobileSearchWrapper = styled.div`
	position: absolute;
	top: 100%;
	width: 100%;
	background-color: #fff;
	height: ${({ theme }) => theme.sizes.mobileSearch};
	box-shadow: 0px 14px 24px rgba(219, 229, 231, 0.3);

	${media.desktopSmall`
		display: none;
	`}
`
