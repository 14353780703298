import styled from 'styled-components'
import { media } from 'constants/media/media'

export const Wrapper = styled.div`
	display: flex;
	align-items: center;
	max-height: 100%;

	svg {
		width: 1.9rem;
	}

	${media.tablet`
		svg {
			width: 3rem;
		}
	`}
`

export const LogoText = styled.div`
	display: none;
	margin-left: 1rem;
	font-size: 2rem;
	font-weight: 700;
	color: ${({ theme }) => theme.colors.textDark};

	${media.mobileMedium`
		display: block;
		margin-left: 1.5rem;
		font-size: 2.7rem;
	`}
`
