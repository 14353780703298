import NextLink, { LinkProps } from 'next/link'

interface FunctionLinkProps extends LinkProps {
	/* execute some functionality before link is redirected*/
	beforeRedirect?: () => void
}
const FunctionLink: React.FunctionComponent<FunctionLinkProps> = ({ children, as, href, beforeRedirect, ...props }) => {
	const handleOnClick = () => {
		if (beforeRedirect) {
			beforeRedirect()
		}
	}
	return (
		<div onClick={handleOnClick}>
			<NextLink {...props} href={href}>
				{children}
			</NextLink>
		</div>
	)
}

export default FunctionLink
