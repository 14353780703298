import React, { FC } from 'react'
import * as StyledUserMenu from './mobileUserMenuHeader.styles'

interface MobileUserMenuHeaderProps {
	name?: string
	id?: string
}

const MobileUserMenuHeader: FC<MobileUserMenuHeaderProps> = ({ name, id }) => (
	<StyledUserMenu.Wrapper>
		<StyledUserMenu.UserMenuCompanyInfoWrapper>
			<StyledUserMenu.CicledInitial>{name && name.length > 0 ? name[0] : ''}</StyledUserMenu.CicledInitial>
			<StyledUserMenu.UserMenuCompanyInfo>
				<StyledUserMenu.CompanyInfoName>{name || ''}</StyledUserMenu.CompanyInfoName>
				<StyledUserMenu.CompanyInfoId>{id && id !== '' ? 'Kt. ' + id : ''}</StyledUserMenu.CompanyInfoId>
			</StyledUserMenu.UserMenuCompanyInfo>
		</StyledUserMenu.UserMenuCompanyInfoWrapper>
	</StyledUserMenu.Wrapper>
)

export default MobileUserMenuHeader
