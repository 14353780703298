export const GA_TRACKING_ID = 'UA-169269522-1'

// @ts-ignore
export const cookiehub = typeof window !== 'undefined' ? window.cookiehub : null

// @ts-ignore
export const gtag: (event: string, id: string, config: any) => void | null =
	// @ts-ignore
	typeof window !== 'undefined' ? window.gtag : null

export const isTrackingEnabled = () => {
	if (cookiehub && cookiehub.hasConsented('analytics')) {
		// @ts-ignore
		window[`ga-disable-${GA_TRACKING_ID}`] = false
		return true
	}

	return false
}

export const trackPageView = (url?: string, title?: string) => {
	if (isTrackingEnabled() && gtag)
		gtag(
			'config',
			GA_TRACKING_ID,
			url && title
				? {
						page_location: url,
						page_title: title,
				  }
				: {}
		)
}
