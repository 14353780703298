import axios, { AxiosResponse } from 'axios'
import { API_URL } from 'constants/paths/paths'
import StorefrontCategory from '@local-types/categories/storefrontCategory'

interface CategoryQuery {
	search?: string
	slugs?: string[]
	ids?: string[]
}

export const get = async (params: CategoryQuery): Promise<AxiosResponse<StorefrontCategory[]>> => {
	return axios(`${API_URL}/categories/`, {
		params: {
			search: params.search,
			slugs: params.slugs?.join(','),
			ids: params.ids?.join(','),
		},
	})
}
