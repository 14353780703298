import React from 'react'
import { createGlobalStyle, css } from 'styled-components'
import { media } from 'constants/media/media'

interface Props {
	sidebarOpen?: boolean
	sidebarFixed?: boolean
}

export const MessengerStyle = createGlobalStyle<Props>`
	body {
		.fb_dialog, .fb-customerchat {
			${({ sidebarOpen }) =>
				sidebarOpen &&
				css`
					display: none;
				`}

			${media.desktopLarge<Props>`
				${({ sidebarFixed }) =>
					sidebarFixed &&
					css`
						display: none;
					`}
			`}
		}
	}
`

export const Messenger = () => (
	<div
		dangerouslySetInnerHTML={{
			__html: `<!-- Load Facebook SDK for JavaScript -->
			<div id="fb-root"></div>
			<script>
			window.fbAsyncInit = function() {
				FB.init({
				xfbml            : true,
				version          : 'v8.0'
				});
			};

			(function(d, s, id) {
			var js, fjs = d.getElementsByTagName(s)[0];
			if (d.getElementById(id)) return;
			js = d.createElement(s); js.id = id;
			js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
			fjs.parentNode.insertBefore(js, fjs);
			}(document, 'script', 'facebook-jssdk'));</script>

			<!-- Your Chat Plugin code -->
			<div class="fb-customerchat"
			attribution=setup_tool
			page_id="574752646064608"
			theme_color="#4BADC2"
			greeting_dialog_display="hide"
			logged_in_greeting="Hæ, hvernig getum við aðstoðað?"
			logged_out_greeting="Hæ, hvernig getum við aðstoðað?">
			</div>`,
		}}
	/>
)
