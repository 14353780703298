import withRedux from 'next-redux-wrapper'
import App from 'next/app'
import React from 'react'
import { NextPage, NextPageContext } from 'next'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import GlobalStyle from '../constants/globalStyle'
import theme from '../constants/theme'
import { initStore } from '../store'
import Layout from '../components/layout'
import { Store } from 'redux'
import '../modules/next-page-transitions.d.ts'
// @ts-ignore
import { PageTransition } from 'next-page-transitions'
import { StyledPageTransitionWrapper } from 'components/base/pageTransitions/pageTransitions'
import { Client } from 'prismicConfiguration'
import BasicInfoPage from 'interfaces/prismic/basicInfo'
import MobileMenu from 'components/menus/mobileMenu'
import { configureAmplify } from 'utils/amplify'
import Modals from 'components/modals'
import { StyledToastContainer } from 'components/utils/toasts/toasts'
import Menus from 'interfaces/prismic/menus'
import BasketSidebar from 'components/basketSidebar/basketSidebar'
import { NextRouter } from 'next/router'
import { MessengerStyle } from '../components/messenger/messenger'



interface Props {
	store: Store
	basicInfo: BasicInfoPage
	menus: Menus
}

configureAmplify()

export default withRedux(initStore)(
	class Application extends App<Props> {
		static async getInitialProps({ Component, ctx }: { Component: NextPage; ctx: NextPageContext }) {
			// @ts-ignore
			const basicInfo = this.basicInfo || (await Client().getSingle('basic_info', {}))
			// @ts-ignore
			this.basicInfo = basicInfo
			// @ts-ignore
			const menus = this.menus || (await Client().getSingle('menus', {}))
			// @ts-ignore
			this.menus = menus
			const pageProps = Component.getInitialProps ? await Component.getInitialProps(ctx) : {}

			return { pageProps, basicInfo, menus }
		}

		private renderWithoutLayout(router: NextRouter): boolean {
			const { pathname } = router
			return pathname === '/pdf-vara/[pid]'
		}

		private renderPageWithoutLayout() {
			const { pageProps, Component, router, basicInfo } = this.props
			return (
				<ThemeProvider theme={theme}>
					<GlobalStyle />
					<MessengerStyle sidebarFixed={true} sidebarOpen={true} />
					<Component {...pageProps} basicInfo={basicInfo} key={router.route} />
				</ThemeProvider>
			)
		}

		render() {
			const { Component, pageProps, basicInfo, menus, store, router } = this.props
			if (this.renderWithoutLayout(router)) {
				return this.renderPageWithoutLayout()
			}

			return (
				<ThemeProvider theme={theme}>
					<Provider store={store}>
						<GlobalStyle />
						<MobileMenu />
						<Modals />
						<BasketSidebar />
						<StyledToastContainer />
						<Layout basicInfo={basicInfo} menus={menus}>
							<StyledPageTransitionWrapper>
								<PageTransition timeout={300} classNames="page-transition">
									<Component {...pageProps} key={router.route} />
								</PageTransition>
							</StyledPageTransitionWrapper>
						</Layout>
					</Provider>
				</ThemeProvider>
			)
		}
	}
)
