import { useDispatch } from 'react-redux'
import useTypedSelector from 'interfaces/useTypedSelector'
import { useActiveCompany } from 'hooks/useActiveCompany/useActiveCompany'
import { useEffect } from 'react'
import { getStorageRecentProducts } from 'helpers/productHelpers/productHelpers'
import { fetchRecentProducts } from 'reducers/productReducer'

export const useRecentProductsStorage = () => {
	const dispatch = useDispatch()
	const { recentlyViewed } = useTypedSelector(({ products }) => products)
	const { activeRelation } = useActiveCompany()
	const { companyId, userId, status } = recentlyViewed

	useEffect(() => {
		if (activeRelation && activeRelation.id !== userId && (status === 'passive' || status === 'fulfilled')) {
			const storedProducts = getStorageRecentProducts(activeRelation.id)
			dispatch(fetchRecentProducts(activeRelation.company.id, activeRelation.id, storedProducts.products))
		}
	}, [activeRelation, companyId, userId, status])
}
