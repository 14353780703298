import { useRef, useState, useEffect } from 'react'
import ResizeObserver from 'resize-observer-polyfill'

export default function useMeasure() {
	// Don't wanna define this as a div element but
	// typescript isn't too happy about binding it any looser than that
	const ref = useRef<HTMLDivElement>(null)
	const [bounds, set] = useState({ left: 0, top: 0, width: 0, height: 0 })

	useEffect(() => {
		const ro = new ResizeObserver(([entry]) => {
			set(entry.contentRect)
		})

		if (ref.current) ro.observe(ref.current)
		return () => ro.disconnect()
	}, [])

	return { bind: { ref }, bounds: bounds }
}
