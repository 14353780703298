import styled, { css, createGlobalStyle } from 'styled-components'
import { zIndex } from 'constants/zIndex/zIndex'
import { H2 } from 'components/base/typography/headings'
import { animated } from 'react-spring'
import { media } from 'constants/media/media'

interface WrapperProps {
	active: 'active' | 'inactive'
}

export const Wrapper = styled(animated.div)<WrapperProps>`
	position: fixed;
	top: 10.2rem;
	left: 0;
	width: 100%;
	height: calc(100% - 10.2rem);
	background-color: #fff;
	z-index: ${zIndex.mobileMenu};
	pointer-events: ${({ active }) => (active === 'active' ? 'auto' : 'none')};

	${media.tablet`
		top: 12.2rem;
	`}

	${media.desktopSmall`
		display: none;
	`}
`

export const MenuCategoryNav = styled.nav`
	padding: 2.8rem 4.8rem;
	max-height: calc(100vh - 6rem - 5.6rem);
	overflow-y: auto;
`

export const MenuCategory = styled(animated.div)`
	&:last-child {
		margin-bottom: 5rem; /* So that content doesn't go behind bottom toolbar on Safari */
	}
`

interface TriggerProps {
	active: boolean
}

export const MenuCategoryTrigger = styled(H2)<TriggerProps>`
	padding: 1.5rem 0;
	transition: color 200ms;

	${({ active, theme }) =>
		active &&
		css`
			color: ${theme.colors.primary};
		`}
`

export const SubMenu = styled(animated.div)`
	overflow: hidden;
`

export const SubMenuItem = styled(animated.a)`
	display: block;
	padding: 0.5rem 0;
	font-size: 1.8rem;
	font-weight: 400;
	color: ${({ theme }) => theme.colors.textColor};

	&:first-child {
		padding-top: 1.5rem;
	}

	&:last-child {
		padding-bottom: 1.5rem;
	}
`

export const DisableBodyScroll = createGlobalStyle`
	body {
		overflow-y: hidden;
	}
`
