import BasketIcon from 'icons/basket.svg'
import * as StyledBasket from './headerBasket.styles'
import { FC, useState, useEffect } from 'react'
import useBasket from '../../../hooks/useBasket'
import { Spinner } from 'components/base/spinner/spinner'
import { useDispatch } from 'react-redux'
import { setBasketVisibleState } from 'reducers/basketReducer'

const HeaderBasket: FC<{}> = () => {
	const dispatch = useDispatch()
	const { productStatus, products, sidebarOpen, sidebarFixed } = useBasket()
	const [cartPulse, setCartPulse] = useState('')

	useEffect(() => {
		if (cartPulse === '') {
			setCartPulse('active')
			setTimeout(() => {
				setCartPulse('')
			}, 500)
		}
	}, [products])

	const handleToggleBasket = () => {
		dispatch(setBasketVisibleState(!sidebarOpen))
	}

	return (
		<StyledBasket.Wrapper active={sidebarOpen} className={sidebarFixed ? 'fixed' : ''}>
			<StyledBasket.BasketTrigger onClick={handleToggleBasket}>
				{products.length > 0 && (
					<StyledBasket.BasketCount className={cartPulse}>
						{productStatus === 'pending' ? <Spinner size="1rem" /> : <span>{products.length}</span>}
					</StyledBasket.BasketCount>
				)}
				<StyledBasket.BasketIconWrapper>
					<BasketIcon />
				</StyledBasket.BasketIconWrapper>
			</StyledBasket.BasketTrigger>
		</StyledBasket.Wrapper>
	)
}

export default HeaderBasket
