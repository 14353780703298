import React, { FC } from 'react'
import Link from 'next/link'
import * as StyledProductBox from './simpleListProductBox.styles'
import StorefrontProduct from '@local-types/products/storefrontProduct'
import { getCroppedProdImg } from '../../../../helpers/productHelpers/productHelpers'

interface Props {
	value: string
	product: StorefrontProduct
}

const getParts = (text: string, highlight: string): string[] => text.split(new RegExp(`(${highlight})`, 'gi'))

const SimpleListProductBox: FC<Props> = ({ product, value }) => {
	const sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, '')
	const parts = getParts(product.sku, sanitizedValue)

	return (
		<Link href={`/vara/${product.slug}`}>
			<StyledProductBox.WrapperLink href={`/vara/${product.slug}`}>
				<StyledProductBox.ProductImageWrapper>
					<StyledProductBox.ProductImage
						src={getCroppedProdImg(product.images[0]?.url || '', 180, 180)}
						alt={product.images[0]?.altText}
					/>
				</StyledProductBox.ProductImageWrapper>
				<StyledProductBox.ProductContent>
					<StyledProductBox.ProductTitle>{product.name}</StyledProductBox.ProductTitle>
					<span>
						<StyledProductBox.Preset>Vörunúmer: </StyledProductBox.Preset>
						<span>
							{parts.map((part: string, i: number) => (
								<StyledProductBox.ProductSku key={i} highlight={part.toLowerCase() === value.toLowerCase()}>
									{part}
								</StyledProductBox.ProductSku>
							))}
						</span>
					</span>
				</StyledProductBox.ProductContent>
			</StyledProductBox.WrapperLink>
		</Link>
	)
}

export default SimpleListProductBox
