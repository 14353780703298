const theme = {
	breakpoints: ['320px', '375px', '425px', '768px', '1024px', '1440px', '1920px'],
	colors: {
		primaryLight: '#4CC3DD',
		primary: '#4BADC2',
		primaryAlt: '#CADAEF',
		primaryDark: '#4195A8',
		secondaryLight: '#f9cbcc',
		secondary: '#E84344',
		secondaryDark: '#D24545',
		textDark: '#0A3039',
		textColor: '#52686E',
		textLight: '#4E555A',
		grayLightest: '#EEF3F4',
		grayLighter: '#F4F7FB',
		grayLight: '#CFD7E0',
		gray: '#BCC9CE',
		grayDark: '#8FA0A4',
		grayBlue: '#F7FBFC',
		grayBlueDark: '#E4F3F6',
		borderLight: '#E9ECED',
		borderXLight: '#F1F1F1',
		borderColor: '#E6E6EC',
		borderDark: '#0A3039',
		borderAlt: '#D4E3E7',
		green: '#41D496',
		greenLight: '#EBF8F2',
		greenDark: '#3eca8d',
		anchorBlue: '#4aadc2',
		warning: '#ffbb33',
		warningLight: '#ffeecc',
		blueDark: '#071e24',
		white: '#fff',
		boxOverlay: 'rgba(5, 64, 91, 0.7)',
	},
	fonts: {
		fontSize: '1.4rem',
		fontSizeLarge: '1.6rem',
		fontFamily: 'Averta, Arial',
	},
	sizes: {
		headerMobile: '6rem',
		headerDesktop: '8rem',
		mobileSearch: '4.2rem',
	},
}

type ThemeInterface = typeof theme

declare module 'styled-components' {
	// eslint-disable-next-line @typescript-eslint/no-empty-interface
	export interface DefaultTheme extends ThemeInterface {}
}

export default theme
