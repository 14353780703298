import React, { FC, useEffect } from 'react'
import * as StyledLayout from './layout.styles'
import Header from '../header'
import Footer from '../footer'
import BasicInfoPage from 'interfaces/prismic/basicInfo'
import { useDispatch } from 'react-redux'
import { fetchFilters } from 'reducers/filtersReducer'
import Menus from 'interfaces/prismic/menus'
import useBasketStorage from 'hooks/useBasketStorage/useBasketStorage'
import useRecentProductsStorage from 'hooks/useRecentProductsStorage'
import useAuthentication from 'hooks/useAuthentication'
import useInactivityTracking from 'hooks/useInactivityTracking'
import Seo from 'components/seo'
// @ts-ignore
import { RichText } from 'prismic-reactjs'
import { useRouter } from 'next/router'
import { trackPageView } from 'helpers/analyticsHelpers/analyticsHelpers'
import useTypedSelector from 'interfaces/useTypedSelector'
import { MessengerStyle } from 'components/messenger/messenger'
import BreadCrumbs from '../breadCrumbs'
import { useProductList } from '../../hooks/useProductList/useProductList'

interface Props {
	basicInfo: BasicInfoPage
	menus: Menus
}

const Layout: FC<Props> = ({ basicInfo, menus, children }) => {
	const router = useRouter()
	const onListPage = router.pathname.includes('/minar-sidur/listar')
	const { sidebarFixed, sidebarOpen } = useTypedSelector(({ basket }) => basket)
	const dispatch = useDispatch()
	const { verifySession } = useAuthentication()
	useInactivityTracking()
	useProductList()

	const renderBreadCrumbs = () => {
		// Note: we dont want to render breadcrumbs on frontpage and we render special breadcrumbs for /vorur page & /minar-sidur/*
		if (router.asPath === '/' || router.pathname === '/vorur' || router.pathname.includes('/minar-sidur')) {
			return null
		}

		return <BreadCrumbs />
	}

	useBasketStorage()
	useRecentProductsStorage()

	useEffect(() => {
		trackPageView()
		verifySession()
		dispatch(fetchFilters())
		router.events.on('routeChangeComplete', url => {
			setTimeout(() => {
				trackPageView(url, document.title)
			}, 0)
		})
	}, [])

	return (
		<StyledLayout.Wrapper isOnListPage={onListPage}>
			<MessengerStyle sidebarFixed={sidebarFixed} sidebarOpen={sidebarOpen} />
			<Seo
				title={RichText.asText(basicInfo.data.meta_title)}
				description={RichText.asText(basicInfo.data.meta_description)}
				imageUrl={basicInfo.data.meta_image.url}
			/>
			<StyledLayout.HeaderPadder />
			<Header menus={menus} />
			{renderBreadCrumbs()}
			{children}
			<Footer basicInfo={basicInfo} />
		</StyledLayout.Wrapper>
	)
}

export default Layout
