import { useDispatch } from 'react-redux'
import { clearProductLists, fetchProductLists } from '../../reducers/productListReducer'
import useTypedSelector from '../../interfaces/useTypedSelector'
import { useEffect } from 'react'
import useActiveCompany from '../useActiveCompany'

export const useProductList = () => {
	const dispatch = useDispatch()
	const { authState } = useTypedSelector(({ auth }) => auth)
	const { activeRelation } = useActiveCompany()

	const setProductLists = async () => {
		if (authState === 'loggedIn' && activeRelation) {
			dispatch(fetchProductLists(activeRelation))
			return
		}
		dispatch(clearProductLists())
	}

	useEffect(() => {
		setProductLists()
	}, [authState, activeRelation])
}
