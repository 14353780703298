import React, { FC, useState } from 'react'
import * as StyledUserMenu from './headerUserMenu.styles'
import useTypedSelector from 'interfaces/useTypedSelector'
import IconUserCircled from 'icons/userCircled.svg'
import IconChevronDown from 'icons/chevronDown.svg'
import useMeasure from 'hooks/useMeasure'
import { useSpring } from 'react-spring'
import { Auth } from 'aws-amplify'
import { useActiveCompany } from 'hooks/useActiveCompany/useActiveCompany'
import { useDispatch } from 'react-redux'
import { setActiveModal } from 'reducers/modalReducer'
import IconRestart from 'icons/restart.svg'
import Link from 'next/link'
import { setBasketVisibleState } from 'reducers/basketReducer'
import MobileUserMenuHeader from './mobileUserMenuHeader'

const HeaderUserMenu: FC<{}> = () => {
	const dispatch = useDispatch()
	const [open, setOpen] = useState(false)
	const { user, userInfo } = useTypedSelector(({ auth }) => auth)
	const { bind, bounds } = useMeasure()
	const props = useSpring({ height: open ? bounds.height : 0 })
	const { activeRelation } = useActiveCompany()

	const toggleUserMenu = () => {
		dispatch(setBasketVisibleState(false))
		setOpen(!open)
	}

	const handleSetCompanyClick = () => {
		setOpen(false)
		dispatch(setActiveModal('company-picker'))
	}

	const handleLogoutClick = async () => {
		await Auth.signOut()
		location.reload()
	}

	const displayName = userInfo && !activeRelation ? user?.name : activeRelation?.company.name

	return (
		<StyledUserMenu.Wrapper active={open}>
			{open && <StyledUserMenu.Overlay onClick={toggleUserMenu} />}
			<StyledUserMenu.MenuTrigger active={open} onClick={toggleUserMenu}>
				<StyledUserMenu.UserIconWrapper active={open}>
					<IconUserCircled />
					<StyledUserMenu.CicledInitial>
						{displayName && displayName.length > 0 ? displayName[0] : ''}
					</StyledUserMenu.CicledInitial>
				</StyledUserMenu.UserIconWrapper>
				<StyledUserMenu.UserDisplayName>{displayName}</StyledUserMenu.UserDisplayName>
				<StyledUserMenu.ChrevonIconWrapper>
					<IconChevronDown />
				</StyledUserMenu.ChrevonIconWrapper>
			</StyledUserMenu.MenuTrigger>
			<StyledUserMenu.UserMenu style={props}>
				<StyledUserMenu.UserMenuInner {...bind}>
					<MobileUserMenuHeader
						name={displayName}
						id={userInfo && activeRelation ? activeRelation?.company.customerId : ''}
					/>
					<StyledUserMenu.ServicePortalNavigation>
						{activeRelation?.canSeeOrderHistory && (
							<Link href="/minar-sidur/pantanir">
								<StyledUserMenu.ServicePortalLink onClick={toggleUserMenu} href="/minar-sidur/pantanir">
									Pantanir
								</StyledUserMenu.ServicePortalLink>
							</Link>
						)}
						<Link href="/minar-sidur/listar">
							<StyledUserMenu.ServicePortalLink onClick={toggleUserMenu} href="/minar-sidur/listar">
								Listar
							</StyledUserMenu.ServicePortalLink>
						</Link>
						{activeRelation?.role === 'Admin' && (
							<Link href="/minar-sidur/minir-notendur">
								<StyledUserMenu.ServicePortalLink onClick={toggleUserMenu} href="/minar-sidur/minir-notendur">
									Mínir notendur
								</StyledUserMenu.ServicePortalLink>
							</Link>
						)}
						<Link href="/minar-sidur/stillingar">
							<StyledUserMenu.ServicePortalLink onClick={toggleUserMenu} href="/minar-sidur/stillingar">
								Stillingar
							</StyledUserMenu.ServicePortalLink>
						</Link>
					</StyledUserMenu.ServicePortalNavigation>
					<StyledUserMenu.ControlButton colorTheme="dark" onClick={handleSetCompanyClick}>
						Skipta um viðskiptanúmer
						<IconRestart />
					</StyledUserMenu.ControlButton>
					<StyledUserMenu.ControlButton colorTheme="light" onClick={handleLogoutClick}>
						Útskráning
					</StyledUserMenu.ControlButton>
					<StyledUserMenu.MobileScreenFill />
				</StyledUserMenu.UserMenuInner>
			</StyledUserMenu.UserMenu>
		</StyledUserMenu.Wrapper>
	)
}

export default HeaderUserMenu
