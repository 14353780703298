import StorefrontCategoryTree from '@local-types/categoryTrees/storefrontCategoryTree'
import useFilters from 'hooks/useFilters'

interface CategoryInfo {
	categoryTree: StorefrontCategoryTree | null
	error: boolean
	loading: boolean
}

const useCategoryTree = (): CategoryInfo => {
	const { filters, loading, error } = useFilters()

	return {
		categoryTree: filters?.mainCategoryTree || null,
		error,
		loading,
	}
}

export default useCategoryTree
