import React, { FC } from 'react'
import * as StyledBasketSidebar from './basketSidebar.styles'
import useTypedSelector from 'interfaces/useTypedSelector'
import { useSpring } from 'react-spring'
import Basket from 'components/basket'
import { useDispatch } from 'react-redux'
import { setBasketVisibleState } from 'reducers/basketReducer'

const BasketSidebar: FC<{}> = () => {
	const dispatch = useDispatch()
	const { sidebarOpen } = useTypedSelector(({ basket }) => basket)

	const props = useSpring({
		transform: sidebarOpen ? 'translate3d(0%, 0, 0)' : 'translate3d(105%, 0, 0)',
	})

	const handleOverlayClick = () => dispatch(setBasketVisibleState(false))

	return (
		<>
			{sidebarOpen && <StyledBasketSidebar.BasketOverlay onClick={handleOverlayClick} />}
			<StyledBasketSidebar.Wrapper style={props}>
				<Basket hideBasket={() => handleOverlayClick()} />
			</StyledBasketSidebar.Wrapper>
		</>
	)
}

export default BasketSidebar
