import React, { FC } from 'react'
import * as StyledHeader from './header.styles'
import Logo from '../logo'
import { FaChevronDown } from 'react-icons/fa'
import Overlay from '../utils/overlay/overlay'
import { zIndex } from '../../constants/zIndex/zIndex'
import CatNavMenu from '../menus/catNavMenu'
import { useDispatch } from 'react-redux'
import { toggleCatNavVisibility, toggleMobileMenuVisibility } from '../../reducers/catNavReducer'
import useTypedSelector from '../../interfaces/useTypedSelector'
import Hamburger from 'components/base/hamburger/hamburger'
import HeaderSearch from './headerSearch'
import HeaderLogin from './headerLogin'
import HeaderUserMenu from './headerUserMenu'
import HeaderLoginLoader from './headerLogin/headerLoginLoader'
import HeaderRoutingLoader from './headerRoutingLoader'
import Menus from 'interfaces/prismic/menus'
import HeaderBasket from './headerBasket'
import FunctionLink from '../functionLink'
import { setBasketVisibleState } from 'reducers/basketReducer'
import HeaderLists from './headerLists'

interface Props {
	menus: Menus
}

const Header: FC<Props> = ({ menus }) => {
	const dispatch = useDispatch()
	const { catNavActive, mobileMenuActive } = useTypedSelector(({ catNav }) => catNav)
	const { authState, userInfoState } = useTypedSelector(({ auth }) => auth)

	const closeBasket = () => dispatch(setBasketVisibleState(false))

	const onCatNavTrigger = () => {
		dispatch(toggleCatNavVisibility(!catNavActive))
		closeBasket()
	}
	const onMobileMenuClick = () => {
		dispatch(toggleMobileMenuVisibility(!mobileMenuActive))
		closeBasket()
	}

	return (
		<StyledHeader.Wrapper>
			<StyledHeader.HeaderPageContainerLarge>
				<StyledHeader.HeaderInner>
					<FunctionLink beforeRedirect={() => mobileMenuActive && onMobileMenuClick()} href="/">
						<StyledHeader.LogoWrapper href="/">
							<Logo />
						</StyledHeader.LogoWrapper>
					</FunctionLink>
					<StyledHeader.DesktopControls>
						<StyledHeader.CatNavWrapper active={catNavActive}>
							{catNavActive && <Overlay onClick={onCatNavTrigger} zIndex={zIndex.catNavMenu - 1} useBoxShadow />}
							<StyledHeader.CatNavTrigger onClick={onCatNavTrigger}>
								<span>Vörur</span>
								<FaChevronDown />
							</StyledHeader.CatNavTrigger>
							<CatNavMenu menus={menus} />
						</StyledHeader.CatNavWrapper>
						<StyledHeader.HeaderSearchWrapper>
							<HeaderSearch htmlId="header-search" placeholder={'Leita...'} />
						</StyledHeader.HeaderSearchWrapper>
						<StyledHeader.HeaderBrandImage ml="3rem" href="https://www.ss.is/" target="_blank">
							<img src="/images/brands/ss-full.jpg" alt="Vörumerki SS" />
						</StyledHeader.HeaderBrandImage>
						<StyledHeader.HeaderBrandImage href="http://www.holta.is/" target="_blank">
							<img src="/images/brands/hollta-large.jpg" alt="Vörumerki Holta" />
						</StyledHeader.HeaderBrandImage>
						<StyledHeader.HeaderBrandImage mr="3rem" height="75%" href="http://www.holltoggott.is/" target="_blank">
							<img src="/images/brands/holtoggott.png" alt="Vörumerki Hollt og Gott" />
						</StyledHeader.HeaderBrandImage>
					</StyledHeader.DesktopControls>
					{(authState === 'pending' || userInfoState === 'pending') && <HeaderLoginLoader />}
					{authState === 'notLoggedIn' && <HeaderLogin />}
					{authState === 'loggedIn' && userInfoState === 'fulfilled' && (
						<>
							<HeaderLists />
							<HeaderUserMenu />
							<HeaderBasket />
						</>
					)}
					<StyledHeader.MobileControls>
						<StyledHeader.HamburgerWrapper onClick={onMobileMenuClick}>
							<Hamburger open={mobileMenuActive} />
						</StyledHeader.HamburgerWrapper>
					</StyledHeader.MobileControls>
				</StyledHeader.HeaderInner>
			</StyledHeader.HeaderPageContainerLarge>
			<StyledHeader.MobileSearchWrapper>
				<HeaderSearch htmlId="mobile-search" placeholder="Leita..." />
			</StyledHeader.MobileSearchWrapper>
			<HeaderRoutingLoader />
		</StyledHeader.Wrapper>
	)
}

export default Header
