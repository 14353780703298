import styled, { css } from 'styled-components'
import { H6 } from 'components/base/typography/headings'

export const WrapperLink = styled.a`
	display: flex;
	align-items: stretch;
	border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
	transition: background-color 250ms;

	&:hover,
	&:active,
	&:focus {
		background-color: ${({ theme }) => theme.colors.grayLighter};
	}
`

export const ProductImageWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 0 0 auto;
	width: 9.4rem;
	height: 6rem;
	border-right: 1px solid ${({ theme }) => theme.colors.borderColor};
`

export const ProductImage = styled.img`
	max-width: 66.667%;
	max-height: 66.667%;
`

export const ProductContent = styled.div`
	display: flex;
	align-items: center;
	padding: 1.5rem;
	justify-content: space-between;
	width: 100%;
`

export const ProductTitle = styled(H6)``
export const Preset = styled.span`
	color: ${({ theme }) => theme.colors.textLight};
`
interface ProductSkuProps {
	highlight: boolean
}

export const ProductSku = styled.span<ProductSkuProps>`
	color: ${({ theme }) => theme.colors.textColor};

	${({ highlight }) =>
		highlight &&
		css`
			font-weight: bold;
			color: ${({ theme }) => theme.colors.textColor};
		`}
`
