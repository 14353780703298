import styled from 'styled-components'
import { media } from 'constants/media/media'

export const Wrapper = styled.div`
	position: relative;
	height: 100%;
	width: 100%;
`

export const HeaderSearchInput = styled.input`
	width: 100%;
	height: 100%;
	padding: 0 2rem;
	padding-right: 5.5rem;
	font-family: ${({ theme }) => theme.fonts.fontFamily};
	font-size: 1.65rem;
	color: ${({ theme }) => theme.colors.textColor};
	font-weight: normal;
	line-height: 100%;
	background-color: #ffffff;

	${media.tablet`
		padding: 0 3rem;
	`}

	${media.desktopSmall`
    	padding-right: 6rem;
		box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.09);
	`}
`

export const SearchLabel = styled.label`
	display: none;
`

export const SearchIconWrapper = styled.div`
	position: absolute;
	top: calc(50% + 0.2rem);
	transform: translateY(-50%);
	right: 2.6rem;
	color: ${({ theme }) => theme.colors.textDark};
	font-size: 2rem;
	line-height: 1;
	pointer-events: none;

	${media.tablet`
		top: calc(50% + 0.4rem);
		right: 4rem;
	`}
`

export const SearchForm = styled.form`
	height: 100%;
`
