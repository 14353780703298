import { media } from 'constants/media/media'
import styled from 'styled-components'

export const Wrapper = styled.div`
	display: flex;
	width: 100%;
	${media.desktopLarge`
		display: none;
    `}
`

export const CicledInitial = styled.div`
	background-color: ${({ theme }) => theme.colors.secondary};
	width: 6rem;
	height: 6rem;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 4rem;
	color: white;
	font-weight: 700;
	border-radius: 100%;
	${media.tablet`
		display: none;
    `}
`

export const UserMenuCompanyInfoWrapper = styled.div`
	display: flex;
	padding: 1.5rem;
	width: 100%;
	background-color: ${({ theme }) => theme.colors.grayLightest};
	padding: 1.5rem 2rem;
	border-bottom: 1px solid ${({ theme }) => theme.colors.grayLightest};
	${media.mobileOnly`
		padding-top: 6rem;
    `}
`

export const CompanyInfoName = styled.div`
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	font-weight: 800;
	font-size: 1.8rem;
`

export const CompanyInfoId = styled.div`
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	font-weight: 600;
	font-size: 1.6rem;
	color: ${({ theme }) => theme.colors.gray};
`

export const UserMenuCompanyInfo = styled.div`
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	margin-left: 1.7rem;
`
