import { useState } from 'react'
import { SearchResults } from 'apiService/search/search'
import searchApi from 'apiService/search'
import { useActiveCompany } from 'hooks/useActiveCompany/useActiveCompany'

export const useSearch = () => {
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(false)
	const [data, setData] = useState<SearchResults>({ products: null, categories: null })
	const { activeRelation } = useActiveCompany()

	const search = async (value: string) => {
		setLoading(true)
		setError(false)

		try {
			const results = await searchApi.get(value, activeRelation?.company.id)
			setData(results)
			setLoading(false)
		} catch {
			setError(true)
			setLoading(false)
		}
	}

	return {
		loading,
		error,
		data,
		search,
	}
}
