import React, { FC } from 'react'
import Head from 'next/head'

interface Props {
	// Title has the prefix "Matarstræti - "
	title: string
	description: string
	imageUrl: string
}

const defaultTags = {
	title: 'Gæði alla leið í íslenskri matargerð',
	description: 'Verslaðu vörur frá SS, Holta og Hollt og gott í nýrri og glæsilegri vefverslun okkar.',
	imageUrl:
		'https://images.prismic.io/matarstraeti/5e12a4c8-0461-4ef9-9bf3-99894637f04c_Vinnusta%C3%B0ir_og_M%C3%B6tuneyti.jpg?auto=compress,format&rect=0,0,3800,1989&w=1200&h=628',
}

const Seo: FC<Props> = props => {
	const { title = defaultTags.title, description = defaultTags.description, imageUrl = defaultTags.imageUrl } = props

	return (
		<>
			<Head>
				{/* Primary Meta Tags */}
				<title>{`Matarstræti - ${title}`}</title>
				<meta name="title" content={title} />
				<meta name="description" content={description} />

				{/* Open Graph / Facebook meta tags */}
				<meta property="og:type" content="website" />
				<meta property="og:url" content="https://matarstraeti.is/" />
				<meta property="og:title" content={title} />
				<meta property="og:description" content={description} />
				<meta property="og:image" content={imageUrl} />
				<meta property="og:image:width" content="1200" />
				<meta property="og:image:height" content="630" />

				{/* Special meta tags for twitter */}
				<meta property="twitter:card" content="summary_large_image" />
				<meta property="twitter:url" content="https://matarstraeti.is/" />
				<meta property="twitter:title" content={title} />
				<meta property="twitter:description" content={description} />
				<meta property="twitter:image" content={imageUrl} />
			</Head>
		</>
	)
}

export default Seo
