import { combineReducers } from 'redux'
import catNavReducer from './catNavReducer'
import productReducer from './productReducer'
import catReducer from './catReducer'
import headerReducer from './headerReducer'
import authReducer from './authReducer'
import modalReducer from './modalReducer'
import servicePortalReducer from './servicePortalReducer'
import filtersReducer from './filtersReducer'
import basketReducer from './basketReducer'
import checkoutReducer from './checkoutReducer'
import { formReducer } from './formReducer'
import productListReducer from './productListReducer'

export default combineReducers({
	catNav: catNavReducer,
	products: productReducer,
	categories: catReducer,
	header: headerReducer,
	auth: authReducer,
	modals: modalReducer,
	servicePortal: servicePortalReducer,
	filters: filtersReducer,
	basket: basketReducer,
	checkout: checkoutReducer,
	form: formReducer,
	productLists: productListReducer,
})
