import { useRouter } from 'next/router'
import { useEffect } from 'react'

type OnRouteChangeStart = (url: string) => void
type OnRouteChangeEnd = (url: string) => void
type OnRouteErrorEnd = (url: string) => void

const useNextRouterEvents = (
	onRouteChangeStart: OnRouteChangeStart,
	onRouteChangeEnd: OnRouteChangeEnd,
	onRouteChangeError: OnRouteErrorEnd
): void => {
	const router = useRouter()

	useEffect(() => {
		router.events.on('routeChangeStart', onRouteChangeStart)
		router.events.on('routeChangeComplete', onRouteChangeEnd)
		router.events.on('routeChangeError', onRouteChangeError)

		return () => {
			router.events.off('routeChangeStart', onRouteChangeStart)
			router.events.off('routeChangeComplete', onRouteChangeEnd)
			router.events.off('routeChangeError', onRouteChangeError)
		}
	}, [])
}

export default useNextRouterEvents
