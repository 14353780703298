import { media } from 'constants/media/media'
import styled from 'styled-components'

export const Wrapper = styled.a`
	width: 14rem;
	height: 100%;
	display: none;
	justify-content: center;
	align-items: center;
	background-color: ${({ theme }) => theme.colors.secondary};
	transition: background-color 250ms ease;
	cursor: pointer;

	${media.desktopSmall`
        display: flex;
    `}

	&:hover {
		background-color: ${({ theme }) => theme.colors.secondaryDark};
		svg {
			transform: scale(1.2);
		}
	}

	svg {
		transition: transform 250ms ease;
		path {
			fill: ${({ theme }) => theme.colors.white};
		}
	}
`
