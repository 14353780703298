import React, { FC, useRef, FormEvent, MouseEvent, useState, ChangeEvent } from 'react'
import * as StyledHeaderLogin from './headerLogin.styles'
import IconUserCircled from 'icons/userCircled.svg'
import useTypedSelector from 'interfaces/useTypedSelector'
import Link from 'next/link'
import { useDispatch } from 'react-redux'
import { setLoginMenuState } from 'reducers/headerReducer'
import { StrongLabel } from 'components/base/forms/label'
import { Input } from 'components/base/forms/input'
import Button, { IconButtonLabel } from 'components/base/buttons/buttons'
import { H4 } from 'components/base/typography/headings'
import { Anchor } from 'components/base/typography/misc'
import { Flex } from 'rebass'
import toastMessage from 'components/utils/toasts/toasts'
import { Auth } from 'aws-amplify'
import { amplifySignInErrorCodeMessageMapper } from 'utils/amplify'
import { setAuthUser, fetchUserInfo } from 'reducers/authReducer'
import { setActiveModal } from 'reducers/modalReducer'
import { toggleMobileMenuVisibility } from 'reducers/catNavReducer'

const HeaderLogin: FC<{}> = () => {
	const dispatch = useDispatch()
	const menuRef = useRef(null)
	const [username, setUsername] = useState('')
	const [password, setPassword] = useState('')
	const [loading, setLoading] = useState(false)
	const { loginMenuOpen } = useTypedSelector(({ header }) => header)

	const handleLoginTriggerClick = () => {
		dispatch(setLoginMenuState(!loginMenuOpen))
		dispatch(toggleMobileMenuVisibility(false))
	}
	const handleUsername = (e: ChangeEvent<HTMLInputElement>) => {
		setUsername(e.target.value)
	}
	const handlePassword = (e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)

	const handleForgottenPasswordClick = (e: MouseEvent) => {
		e.preventDefault()
		dispatch(setActiveModal('forgotten-password'))
	}

	const signIn = async () => {
		setLoading(true)

		try {
			const user = await Auth.signIn(username, password)
			if (user) {
				dispatch(
					setAuthUser({
						authState: 'pending',
						user: user.attributes,
					})
				)
				dispatch(fetchUserInfo())
			}
		} catch (err) {
			// @ts-ignore
			const message: string | undefined = amplifySignInErrorCodeMessageMapper[err.code]

			toastMessage({
				type: 'error',
				title: 'Innskráning tókst ekki',
				message: message || '',
			})

			dispatch(
				setAuthUser({
					authState: 'notLoggedIn',
					user: null,
				})
			)

			setLoading(false)
		}
	}

	const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		signIn()
	}

	return (
		<StyledHeaderLogin.Wrapper ref={menuRef} active={loginMenuOpen}>
			{loginMenuOpen ? <StyledHeaderLogin.Overlay onClick={handleLoginTriggerClick} /> : null}
			<StyledHeaderLogin.LoginTrigger onClick={handleLoginTriggerClick} active={loginMenuOpen}>
				<IconButtonLabel>Innskráning</IconButtonLabel>
				<IconUserCircled />
			</StyledHeaderLogin.LoginTrigger>
			<StyledHeaderLogin.LoginMenu active={loginMenuOpen}>
				<StyledHeaderLogin.LoginMenuForm onSubmit={handleFormSubmit}>
					<StrongLabel htmlFor="username" mb="1rem">
						Netfang
					</StrongLabel>
					<Input
						type="email"
						id="username"
						name="username"
						aria-label="Netfang"
						placeholder="Netfang"
						value={username}
						onChange={handleUsername}
						autoCapitalize="none"
					/>
					<StrongLabel htmlFor="password" mt="1rem" mb="1rem">
						Lykilorð
					</StrongLabel>
					<Input
						type="password"
						id="password"
						name="password"
						aria-label="Lykilorð"
						placeholder="Lykilorð"
						value={password}
						onChange={handlePassword}
					/>
					<Button type="submit" primary block mt="1.5rem" mb="1.5rem" isLoading={loading}>
						Innskráning
					</Button>
					<Flex justifyContent="flex-end">
						<Anchor
							as="button"
							color="textColor"
							underlined
							fontSize="1.2rem"
							fontWeight="700"
							onClick={handleForgottenPasswordClick}>
							Gleymt lykilorð?
						</Anchor>
					</Flex>
				</StyledHeaderLogin.LoginMenuForm>
				<StyledHeaderLogin.LoginMenuFooter>
					<H4 mb="0.5rem">Ekki með aðgang?</H4>
					<Link href="/nyskraning">
						<Anchor
							href="/nyskraning"
							color="secondary"
							underlined
							fontSize="1.4rem"
							fontWeight="700"
							onClick={handleLoginTriggerClick}>
							Nýskráning í vefverslun
						</Anchor>
					</Link>
				</StyledHeaderLogin.LoginMenuFooter>
			</StyledHeaderLogin.LoginMenu>
		</StyledHeaderLogin.Wrapper>
	)
}

export default HeaderLogin
