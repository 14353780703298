import React, { FC } from 'react'
import styled from 'styled-components'
import { fadeIn } from '../../../constants/keyframes/fades'

const StyledOverlayWrapper = styled.div`
	z-index: -1;
`

interface OverlayProps {
	zIndex: number
	useBoxShadow: boolean
}

const StyledOverlay = styled.div<OverlayProps>`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: ${({ zIndex }) => zIndex};
	background: ${({ useBoxShadow }) => (useBoxShadow ? 'none' : 'rgba(0, 0, 0, 0.2)')};
	cursor: pointer;
`

const StyledOverlayShadow = styled.div`
	opacity: 0;
	animation: ${fadeIn} 300ms ease forwards;
	box-shadow: 0px 0px 0px 1000rem rgba(0, 0, 0, 0.2);
	pointer-events: none;
`

interface Props {
	useBoxShadow?: boolean
	onClick: ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) | undefined
	zIndex: number
}

const Overlay: FC<Props> = ({ useBoxShadow, onClick, zIndex }) => {
	return (
		<StyledOverlayWrapper>
			{useBoxShadow && <StyledOverlayShadow />}
			<StyledOverlay onClick={onClick} zIndex={zIndex} useBoxShadow />
		</StyledOverlayWrapper>
	)
}

export default Overlay
