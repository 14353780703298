import React, { FC } from 'react'
import * as StyledHamburger from './hamburger.styles'
import { useSpring, config } from 'react-spring'

interface Props {
	open: boolean
}

const Hamburger: FC<Props> = ({ open }) => {
	const line1Spring = useSpring({
		transform: open ? 'rotate(45deg)' : 'rotate(0deg)',
		top: open ? '8px' : '0px',
		config: config.wobbly,
	})

	const line2Spring = useSpring({
		width: open ? '0%' : '100%',
		config: config.stiff,
	})

	const line3Spring = useSpring({
		transform: open ? 'rotate(-45deg)' : 'rotate(0deg)',
		top: open ? '-8px' : '0px',
		config: config.wobbly,
	})

	return (
		<StyledHamburger.Hamburger>
			<StyledHamburger.HamburgerLine1 style={line1Spring} />
			<StyledHamburger.HamburgerLine2 style={line2Spring} />
			<StyledHamburger.HamburgerLine3 style={line3Spring} />
		</StyledHamburger.Hamburger>
	)
}

export default Hamburger
