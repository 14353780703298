import styled, { css } from 'styled-components'
import { media } from 'constants/media/media'

interface WrapperProps {
	isOnListPage: boolean
}

export const Wrapper = styled.div<WrapperProps>`
	display: flex;
	flex-direction: column;
	min-height: 100vh;

	/*
		HACK ALERT
		In order for position: sticky to work properly, we can't have overflow: hidden on any parent containers.
		So I dont set overflow: hidden if we are on /minar-sidur/listar because that page has a sticky element on mobile.
		But we also want overflow-x: hidden on some pages otherwise things like pop-up labels overflow and make
		the whole page scrollable on x-axis, which is not ideal.
	*/

	${({ isOnListPage }) =>
		!isOnListPage &&
		css`
			${media.tabletAndMobileOnly`
				overflow-x: hidden;
			`}
		`}
`

export const HeaderPadder = styled.div`
	visibility: none;
	padding-top: ${({ theme }) => `calc(${theme.sizes.headerMobile} + ${theme.sizes.mobileSearch})`};

	${media.tablet`
		padding-top: ${({ theme }) => `calc(${theme.sizes.headerDesktop} + ${theme.sizes.mobileSearch})`};
	`}

	${media.desktopSmall`
		padding-top: ${({ theme }) => theme.sizes.headerDesktop};
	`}
`
