import { createAction, createReducer } from '@reduxjs/toolkit'

export interface UserInfoFormData {
	companySocialId: string
	name: string
	jobTitle: string
	tel: string
	email: string
	password: string
	confirmPassword?: string
	comments: string
	postlistSelected: boolean
}

export interface UserInfoInitialFormData {
	name?: string
	tel?: string
	email?: string
	companySocialId?: string
}

export const handleFormChange = createAction<any>('update-form-data')
export const setInitialFormState = createAction<any>('set-initial-form-data')

export const initialState: UserInfoFormData = {
	companySocialId: '',
	name: '',
	jobTitle: '',
	tel: '',
	email: '',
	password: '',
	confirmPassword: '',
	comments: '',
	postlistSelected: true,
}

export const formReducer = createReducer(initialState, builder =>
	builder
		.addCase(handleFormChange, (store, action) => {
			if (action.payload.id) {
				return { ...store, [action.payload.id]: action.payload.value }
			}
			return action.payload
		})
		.addCase(setInitialFormState, (store, action) => {
			return {
				...store,
				name: action.payload.name || '',
				email: action.payload.email || '',
				tel: action.payload.tel || '',
				companySocialId: action.payload.companySocialId || '',
				postlistSelected: action.payload.postlistSelected || true,
			}
		})
)
