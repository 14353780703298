import Amplify from 'aws-amplify'

export const configureAmplify = () => {
	Amplify.configure({
		Auth: {
			// REQUIRED - Amazon Cognito Region
			region: 'eu-west-1',

			// OPTIONAL - Amazon Cognito User Pool ID
			userPoolId: 'eu-west-1_ZlK8W4YVY',

			// OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
			userPoolWebClientId: '18i25enj7r3gsu71be56ogrgvg',

			authenticationFlowType: 'USER_SRP_AUTH',
		},
	})
}

export const amplifySignInErrorCodeMessageMapper = {
	UserNotConfirmedException:
		'Gaman að sjá þig, þú átt eftir að staðfesta netfangið þitt, kíktu í póstinn þinn og sjáðu hvort þú eigir ekki tölvupóst frá okkur þar.',
	PasswordResetRequiredException: 'Endursetja þarf lykilorð, vinsamlegast hafið samband við þjónustu',
	NotAuthorizedException:
		'Gefið netfang og/eða lykilorð var ekki rétt. Vinsamlegast passaðu að þú sért að nota netfangið þitt sem notendanafn.',
	UserNotFoundException: 'Þessi notandi fannst ekki',
}
