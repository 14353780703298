import styled from 'styled-components'
import { media } from 'constants/media/media'
import { H3 } from 'components/base/typography/headings'
import { Column } from 'components/base/grid/grid'

export const Wrapper = styled.footer`
	margin-top: auto;
`

export const Inner = styled.div`
	padding: 8rem 3rem 6rem 3rem;
	background-color: ${({ theme }) => theme.colors.textDark};
	color: ${({ theme }) => theme.colors.gray};
	font-weight: 600;

	${media.tablet`
		padding-left: 10rem;
		padding-right: 10rem;
	`}

	strong {
		color: ${({ theme }) => theme.colors.white};
	}
`

export const LogoBannersWrapper = styled.div`
	margin-top: -8.4rem;
	margin-bottom: 4rem;
`

interface AnchorProps {
	underline?: boolean
	color?: string
}

export const ColumnHeading = styled(H3)`
	margin-top: 3rem;
	margin-bottom: 1.5rem;

	${media.desktopSmall`
		margin-top: 0;
		margin-bottom: 4rem;
	`}
`

export const MainHeading = styled(H3)`
	margin-top: 2rem;
	margin-bottom: 1rem;

	${media.desktopSmall`
		margin-top: 0;
	`}
`

export const FooterAnchor = styled.a<AnchorProps>`
	color: ${({ theme, color }) => color || theme.colors.gray};
	text-decoration: ${({ underline }) => (underline ? 'underline' : 'none')};
	transition: color 200ms;
	cursor: pointer;

	&:hover {
		color: #fff;
	}

	svg {
		margin-right: 2rem;
		cursor: pointer;

		&:hover {
			path {
				fill: ${({ theme }) => theme.colors.secondaryDark};
			}
		}
	}
`

export const FooterLargeAnchor = styled.a`
	color: #fff;
	font-weight: 700;
	font-size: 2rem;
`

export const FlexColumn = styled(Column)`
	display: flex;
	flex-direction: column;
`

export const SsnWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	span {
		color: ${({ theme }) => theme.colors.white};
		margin-right: 0.5rem;
	}
`

export const SocialMediaWrapper = styled.div`
	display: flex;
	flex-grow: 1;
	width: 100%;
	align-items: flex-end;

	margin-top: 0rem;

	${media.desktopSmall`
		margin-top: 0;
	`}

	${media.tabletAndMobileOnly`
		margin-top: 2rem;
	`}
`

export const SocialMediaContent = styled.div`
	display: flex;
	${FooterAnchor} {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
`

export const PostListFormWrapper = styled.div`
	margin-top: 2rem;
	margin-bottom: 3rem;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	flex-grow: 1;
	p {
		margin-bottom: 2rem;
		font-size: 1.4rem;
		${media.desktopSmall`
			margin-bottom: 2rem;
			font-size: 1.6rem;
		`}
	}
`
