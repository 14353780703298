import { keyframes } from 'styled-components'

export const pulse = keyframes`
	0% {
		transform: scale(1);
	}
	33% {
		transform: scale(0.8)
	}
	66% {
		transform: scale(1.4)
	}
	100% {
		transform: scale(1);
	}
`
