import React, { FC, useState } from 'react'
import styled, { css, keyframes } from 'styled-components'
import useNextRouterEvents from 'hooks/useNextRouterEvents'

const routeAnim = keyframes`
	0% { transform: translate3d(-100%, 0, 0) }
	70% { transform: translate3d(0%, 0, 0) }
	100% { opacity: 0, transform: translate3d(0%, 0, 0) }
`

const StyledLoadingWrapper = styled.div`
	position: absolute;
	bottom: -2px;
	left: 0;
	width: 100%;
`

type LoadingBarState = 'passive' | 'routing' | 'routing-completed'

interface LoadingBarProps {
	state: LoadingBarState
}

const StyledLoadingBar = styled.div<LoadingBarProps>`
	height: 2px;
	width: 100%;
	transform: translate3d(-100%, 0, 0);
	background-color: ${({ theme }) => theme.colors.secondary};
	transition: transform 2000ms ease, opacity 400ms ease;

	${({ state }) =>
		state === 'routing' &&
		css`
			transform: translate3d(0%, 0, 0);
		`}

	${({ state }) =>
		state === 'routing-completed' &&
		css`
			opacity: 0;
			transform: translate3d(-100%, 0, 0);
			transition: transform 50ms ease 400ms, opacity 400ms ease;
		`}
`

const HeaderRoutingLoader: FC<{}> = () => {
	const [state, setState] = useState<LoadingBarState>('passive')

	const handleRouteChangeStart = () => setState('routing')
	const handleRouteChangeEnd = () => {
		setTimeout(() => setState('routing-completed'), 1800)
		setTimeout(() => setState('passive'), 2200)
	}

	useNextRouterEvents(handleRouteChangeStart, handleRouteChangeEnd, handleRouteChangeEnd)

	return (
		<StyledLoadingWrapper>
			<StyledLoadingBar state={state} />
		</StyledLoadingWrapper>
	)
}

export default HeaderRoutingLoader
