import React, { FC, useState, ChangeEvent, useEffect, FormEvent, useRef } from 'react'
import * as StyledHeaderSearch from './headerSearch.styles'
import { IoIosSearch } from 'react-icons/io'
import HeaderSearchMenu from './headerSearchMenu'
import { useRouter } from 'next/router'
import useFilters from 'hooks/useFilters'
import { useDispatch } from 'react-redux'
import { toggleCatNavMenu, toggleMobileMenuVisibility, toggleSearchSubmitted } from 'reducers/catNavReducer'
import useTypedSelector from 'interfaces/useTypedSelector'

interface Props {
	htmlId: string
	placeholder?: string
}

const HeaderSearch: FC<Props> = ({ htmlId, placeholder }) => {
	const dispatch = useDispatch()
	const [value, setValue] = useState('')
	const router = useRouter()
	const { menuOpen, mobileMenuActive } = useTypedSelector(({ catNav }) => catNav)
	const { clearFilters } = useFilters()

	const searchRef = useRef<HTMLInputElement>(null)
	// Clears the search input each time the user routes to a new location
	useEffect(() => {
		setValue('')
	}, [router.pathname])

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value)
	const handleInputFocus = () => (value.length > 2 && !menuOpen ? dispatch(toggleCatNavMenu(true)) : null)

	const handleInputSubmit = (e: FormEvent) => {
		e.preventDefault()
		clearFilters(value)
		router.push('/vorur')
		if (mobileMenuActive) dispatch(toggleMobileMenuVisibility(false))
		dispatch(toggleSearchSubmitted(true))
	}

	return (
		<StyledHeaderSearch.Wrapper aria-haspopup={true} aria-expanded={menuOpen}>
			<StyledHeaderSearch.SearchForm onSubmit={handleInputSubmit} action=".">
				<StyledHeaderSearch.SearchLabel id="search-label" htmlFor={htmlId}>
					Leit
				</StyledHeaderSearch.SearchLabel>
				<StyledHeaderSearch.HeaderSearchInput
					onFocus={handleInputFocus}
					placeholder={placeholder || 'Leitaðu að vörunúmeri, vöruheiti eða vöruflokki'}
					value={value}
					onChange={handleChange}
					ref={searchRef}
					type="search"
					id={htmlId}
					name="leit"
					aria-controls="search-results"
					aria-labelledby="search-label"
					autoComplete="off"
				/>
			</StyledHeaderSearch.SearchForm>
			<StyledHeaderSearch.SearchIconWrapper>
				<IoIosSearch />
			</StyledHeaderSearch.SearchIconWrapper>
			<HeaderSearchMenu value={value} />
		</StyledHeaderSearch.Wrapper>
	)
}

export default HeaderSearch
